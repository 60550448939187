/* src/App.svelte generated by Svelte v3.38.2 */
import {
	SvelteComponent,
	append,
	attr,
	create_component,
	destroy_component,
	detach,
	element,
	init,
	insert,
	mount_component,
	noop,
	safe_not_equal,
	transition_in,
	transition_out
} from "../_snowpack/pkg/svelte/internal.js";

import { onMount } from "../_snowpack/pkg/svelte.js";
import Title from "./Title.svelte.js";

function create_fragment(ctx) {
	let main;
	let div;
	let title;
	let current;
	title = new Title({});

	return {
		c() {
			main = element("main");
			div = element("div");
			create_component(title.$$.fragment);
			attr(div, "class", "justify-items-center");
			attr(main, "class", "min-h-screen flex justify-center items-center");
		},
		m(target, anchor) {
			insert(target, main, anchor);
			append(main, div);
			mount_component(title, div, null);
			current = true;
		},
		p: noop,
		i(local) {
			if (current) return;
			transition_in(title.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(title.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(main);
			destroy_component(title);
		}
	};
}

function instance($$self) {
	let count = 0;

	onMount(() => {
		const interval = setInterval(() => count++, 1000);

		return () => {
			clearInterval(interval);
		};
	});

	return [];
}

class App extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default App;