/* src/Title.svelte generated by Svelte v3.38.2 */
import {
	SvelteComponent,
	add_render_callback,
	append,
	attr,
	check_outros,
	create_bidirectional_transition,
	create_component,
	destroy_component,
	detach,
	element,
	group_outros,
	init,
	insert,
	listen,
	mount_component,
	safe_not_equal,
	set_input_value,
	space,
	text,
	transition_in,
	transition_out
} from "../_snowpack/pkg/svelte/internal.js";

import { fade } from "../_snowpack/pkg/svelte/transition.js";
import Explanation from "./Explanation.svelte.js";
import Fingerprinter from "./Fingerprinter.svelte.js";

function create_if_block_1(ctx) {
	let div;
	let input;
	let t0;
	let label;
	let div_transition;
	let current;
	let mounted;
	let dispose;

	return {
		c() {
			div = element("div");
			input = element("input");
			t0 = space();
			label = element("label");
			label.textContent = "I understand, let's go!";
			attr(input, "class", "mr-2");
			attr(input, "id", "agree");
			attr(input, "type", "checkbox");
			attr(label, "for", "agree");
			attr(div, "class", "mt-4");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			append(div, input);
			set_input_value(input, /*agree*/ ctx[1]);
			append(div, t0);
			append(div, label);
			current = true;

			if (!mounted) {
				dispose = listen(input, "change", /*input_change_handler*/ ctx[2]);
				mounted = true;
			}
		},
		p(ctx, dirty) {
			if (dirty & /*agree*/ 2) {
				set_input_value(input, /*agree*/ ctx[1]);
			}
		},
		i(local) {
			if (current) return;

			add_render_callback(() => {
				if (!div_transition) div_transition = create_bidirectional_transition(div, fade, { duration: 400 }, true);
				div_transition.run(1);
			});

			current = true;
		},
		o(local) {
			if (!div_transition) div_transition = create_bidirectional_transition(div, fade, { duration: 400 }, false);
			div_transition.run(0);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div);
			if (detaching && div_transition) div_transition.end();
			mounted = false;
			dispose();
		}
	};
}

// (48:2) {#if Boolean(agree)}
function create_if_block(ctx) {
	let fingerprinter;
	let current;
	fingerprinter = new Fingerprinter({});

	return {
		c() {
			create_component(fingerprinter.$$.fragment);
		},
		m(target, anchor) {
			mount_component(fingerprinter, target, anchor);
			current = true;
		},
		i(local) {
			if (current) return;
			transition_in(fingerprinter.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(fingerprinter.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(fingerprinter, detaching);
		}
	};
}

function create_fragment(ctx) {
	let div1;
	let h1;
	let span;
	let t0;
	let t1;
	let p0;
	let t3;
	let hr;
	let t4;
	let p1;
	let t6;
	let p2;
	let t8;
	let div0;
	let p3;
	let t10;
	let p4;
	let t12;
	let show_if_1 = !Boolean(/*agree*/ ctx[1]);
	let t13;
	let show_if = Boolean(/*agree*/ ctx[1]);
	let t14;
	let br0;
	let t15;
	let explanation;
	let t16;
	let br1;
	let t17;
	let footer;
	let t25;
	let br2;
	let current;
	let if_block0 = show_if_1 && create_if_block_1(ctx);
	let if_block1 = show_if && create_if_block(ctx);
	explanation = new Explanation({});

	return {
		c() {
			div1 = element("div");
			h1 = element("h1");
			span = element("span");
			t0 = text("Fontprint");
			t1 = space();
			p0 = element("p");
			p0.textContent = "See how your installed fonts can be used to track you";
			t3 = space();
			hr = element("hr");
			t4 = space();
			p1 = element("p");
			p1.textContent = "Typography is everywhere on the web. It's something that you probably don't\n    even think about, unless it's pointed out. But it's also something that can\n    be used to uniquely identify your browser.";
			t6 = space();
			p2 = element("p");
			p2.textContent = "This site demonstrates how it can be used to fingerprint you by checking\n    against 3000~ fonts. It'll be slower than a real fingerprinting script, as\n    it provides visual feedback.";
			t8 = space();
			div0 = element("div");
			p3 = element("p");
			p3.textContent = "psst! testing shows rapidly changing text.";
			t10 = space();
			p4 = element("p");
			p4.textContent = "please proceed with caution!";
			t12 = space();
			if (if_block0) if_block0.c();
			t13 = space();
			if (if_block1) if_block1.c();
			t14 = space();
			br0 = element("br");
			t15 = space();
			create_component(explanation.$$.fragment);
			t16 = space();
			br1 = element("br");
			t17 = space();
			footer = element("footer");

			footer.innerHTML = `<p>No data is sent to any servers, everything here is done client side.</p> 
    <p>Cloudflare may set an analytics beacon.</p> 
    <a class="text-blue-600 hover:underline hover:text-blue-400 transition-all" href="https://www.cloudflare.com/privacypolicy/" target="_blank" rel="noopener">Cloudflare&#39;s privacy policy</a> 
    <a class="text-blue-600 hover:underline hover:text-blue-400 transition-all" href="https://github.com/EmeraldSnorlax/fontprint/tree/main" target="_blank" rel="noopener">Source Code (GNU AGPL-3.0)</a>`;

			t25 = space();
			br2 = element("br");
			attr(span, "style", /*font*/ ctx[0]);
			attr(h1, "class", "text-center text-4xl h-16 align-baseline");
			attr(p0, "class", "text-center text-xl");
			attr(hr, "class", "mt-4");
			attr(p1, "class", "my-4");
			attr(p4, "class", "text-red-700 font-bold");
			attr(div0, "class", "warning");
			attr(footer, "class", "text-center");
			attr(div1, "class", "md:max-w-2xl max-w-screen-sm p-2");
		},
		m(target, anchor) {
			insert(target, div1, anchor);
			append(div1, h1);
			append(h1, span);
			append(span, t0);
			append(div1, t1);
			append(div1, p0);
			append(div1, t3);
			append(div1, hr);
			append(div1, t4);
			append(div1, p1);
			append(div1, t6);
			append(div1, p2);
			append(div1, t8);
			append(div1, div0);
			append(div0, p3);
			append(div0, t10);
			append(div0, p4);
			append(div0, t12);
			if (if_block0) if_block0.m(div0, null);
			append(div1, t13);
			if (if_block1) if_block1.m(div1, null);
			append(div1, t14);
			append(div1, br0);
			append(div1, t15);
			mount_component(explanation, div1, null);
			append(div1, t16);
			append(div1, br1);
			append(div1, t17);
			append(div1, footer);
			append(div1, t25);
			append(div1, br2);
			current = true;
		},
		p(ctx, [dirty]) {
			if (!current || dirty & /*font*/ 1) {
				attr(span, "style", /*font*/ ctx[0]);
			}

			if (dirty & /*agree*/ 2) show_if_1 = !Boolean(/*agree*/ ctx[1]);

			if (show_if_1) {
				if (if_block0) {
					if_block0.p(ctx, dirty);

					if (dirty & /*agree*/ 2) {
						transition_in(if_block0, 1);
					}
				} else {
					if_block0 = create_if_block_1(ctx);
					if_block0.c();
					transition_in(if_block0, 1);
					if_block0.m(div0, null);
				}
			} else if (if_block0) {
				group_outros();

				transition_out(if_block0, 1, 1, () => {
					if_block0 = null;
				});

				check_outros();
			}

			if (dirty & /*agree*/ 2) show_if = Boolean(/*agree*/ ctx[1]);

			if (show_if) {
				if (if_block1) {
					if (dirty & /*agree*/ 2) {
						transition_in(if_block1, 1);
					}
				} else {
					if_block1 = create_if_block(ctx);
					if_block1.c();
					transition_in(if_block1, 1);
					if_block1.m(div1, t14);
				}
			} else if (if_block1) {
				group_outros();

				transition_out(if_block1, 1, 1, () => {
					if_block1 = null;
				});

				check_outros();
			}
		},
		i(local) {
			if (current) return;
			transition_in(if_block0);
			transition_in(if_block1);
			transition_in(explanation.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(if_block0);
			transition_out(if_block1);
			transition_out(explanation.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div1);
			if (if_block0) if_block0.d();
			if (if_block1) if_block1.d();
			destroy_component(explanation);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let font = "font-family: sans-serif;";
	let i = 1;

	let styles = [
		"font-family: sans-serif;",
		"font-family: monospace;",
		"font-family: cursive;",
		"font-family: serif;"
	];

	setInterval(
		() => {
			$$invalidate(0, font = styles[i]);
			i = (i + 1) % styles.length;
		},
		500
	);

	let agree = false;

	function input_change_handler() {
		agree = this.value;
		$$invalidate(1, agree);
	}

	return [font, agree, input_change_handler];
}

class Title extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default Title;