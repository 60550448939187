let fontsWithPossibleDuplication = [
  "Adobe Jenson",
  "Albertus",
  "Aldus",
  "Alexandria",
  "Algerian",
  "Amelia",
  "American Typewriter",
  "Antiqua",
  "Arno*",
  "Aster",
  "Aurora",
  "News 706",
  "Baskerville",
  "Bell",
  "Belwe Roman",
  "Bembo",
  "Berkeley Old Style",
  "Bernhard Modern",
  "Bodoni",
  "Bauer Bodoni",
  "Bookman",
  "Bulmer",
  "Caledonia",
  "Californian FB",
  "Calisto MT",
  "Cambria",
  "Capitals",
  "Cartier",
  "Caslon",
  "Wyld",
  "Caslon Antique",
  "Fifteenth Century",
  "Catull",
  "Centaur",
  "Century Old Style",
  "Century Schoolbook",
  "New Century Schoolbook",
  "Century Schoolbook Infant",
  "Charis SIL",
  "Charter",
  "Cheltenham",
  "Clearface",
  "Cochin",
  "Computer Modern",
  "Concrete Roman",
  "Constantia",
  "Copperplate Gothic",
  "Corona",
  "News 705",
  "DejaVu Serif",
  "Didot",
  "Droid Serif",
  "Elephant",
  "Emerson",
  "Excelsior",
  "News 702",
  "Fairfield",
  "FF Scala",
  "Footlight",
  "FreeSerif",
  "Friz Quadrata",
  "Garamond",
  "Gentium",
  "Georgia",
  "Gloucester",
  "Goudy Old Style",
  "Goudy",
  "Granjon",
  "High Tower Text",
  "Hoefler Text",
  "IBM Plex Serif",
  "Imprint",
  "Ionic No. 5",
  "News 701",
  "ITC Benguiat",
  "Janson",
  "Jokerman",
  "Joanna",
  "Korinna",
  "Lexicon",
  "Liberation Serif",
  "Linux Libertine",
  "Literaturnaya",
  "Lucida Bright",
  "Melior",
  "Memphis",
  "Miller",
  "Minion",
  "Modern",
  "Mona Lisa",
  "Mrs Eaves",
  "MS Serif",
  "New York",
  "Nimbus Roman",
  "NPS Rawlinson Roadway",
  "OCR A Extended",
  "Palatino",
  "Book Antiqua",
  "Perpetua",
  "Plantin",
  "Playbill",
  "Primer",
  "PT Serif",
  "Renault",
  "Requiem",
  "Rotis Serif",
  "Sabon",
  "Sistina",
  "Source Serif Pro",
  "Souvenir",
  "STIX",
  "Sylfaen",
  "Theano Didot",
  "Times New Roman",
  "Times",
  "Torino",
  "Trajan",
  "Trinité",
  "Trump Mediaeval",
  "Utopia",
  "Vera Serif",
  "Wide Latin",
  "Windsor",
  "XITS",
  "Alexandria",
  "American Typewriter",
  "Archer",
  "Athens",
  "Candida",
  "Cholla Slab",
  "City",
  "Clarendon",
  "Concrete Roman",
  "Courier",
  "Egyptienne",
  "Guardian Egyptian",
  "Ionic No. 5",
  "Lexia",
  "Memphis",
  "Nilland",
  "Roboto Slab",
  "Rockwell",
  "Schadow",
  "Serifa",
  "Skeleton Antique",
  "Tower",
  "Agency FB",
  "Akzidenz-Grotesk",
  "Andalé Sans",
  "Antique Olive",
  "Arial",
  "Arial Unicode MS",
  "Avant Garde Gothic",
  "Avenir",
  "Bank Gothic",
  "Bauhaus",
  "Bell Centennial",
  "Bell Gothic",
  "Benguiat Gothic",
  "Berlin Sans",
  "Brandon Grotesque",
  "Calibri",
  "Casey",
  "Century Gothic",
  "Charcoal",
  "Chicago",
  "Clearview",
  "Comic Sans",
  "Compacta",
  "Corbel",
  "DejaVu Sans",
  "DIN",
  "Dotum",
  "Droid Sans",
  "Dyslexie",
  "Ecofont",
  "Eras",
  "Esseltub",
  "Espy Sans",
  "Eurocrat",
  "Eurostile",
  "Square 721",
  "FF Dax",
  "FF Meta",
  "FF Scala Sans",
  "Fira Sans",
  "Fira Mono",
  "Fira Code",
  "Fira Go",
  "Folio",
  "Franklin Gothic",
  "FreeSans",
  "Frutiger",
  "Futura",
  "Geneva",
  "Gill Sans",
  "Gill Sans Schoolbook",
  "Gotham",
  "Haettenschweiler",
  "Handel Gothic",
  "Hei",
  "Helvetica",
  "Helvetica Neue",
  "Swiss 721",
  "Highway Gothic",
  "IBM Plex Sans",
  "Impact",
  "Industria",
  "Interstate",
  "Johnston",
  "New Johnston",
  "Kabel",
  "Klavika",
  "Lato",
  "Liberation Sans",
  "Linux Biolinum",
  "Lucida Sans",
  "Lucida Grande",
  "Lucida Sans Unicode",
  "Lydian",
  "Meiryo",
  "Meta",
  "Microgramma",
  "Modern",
  "Motorway",
  "MS Sans Serif",
  "Myriad",
  "Neutraface",
  "Neuzeit S",
  "News Gothic",
  "Nimbus Sans L",
  "Nordstern",
  "Open Sans",
  "Optima",
  "Overpass",
  "Parisine",
  "Product Sans",
  "Proxima Nova",
  "PT Sans",
  "Rail Alphabet",
  "Roboto",
  "Rotis Sans",
  "San Francisco",
  "Segoe UI",
  "Skia",
  "Source Sans Pro",
  "Sweden Sans",
  "Syntax",
  "System",
  "Tahoma",
  "Template Gothic",
  "Thesis Sans",
  "Tiresias",
  "Trade Gothic",
  "Transport",
  "Trebuchet MS",
  "Twentieth Century",
  "Ubuntu",
  "Unica",
  "Univers",
  "Zurich",
  "Vera Sans",
  "Verdana",
  "Nyala",
  "Rotis Semi Serif",
  "EasyReading",
  "3x3",
  "Andalé Mono",
  "Arial Monospaced",
  "Bitstream Vera",
  "Consolas",
  "Courier",
  "Courier New",
  "Cutive Mono",
  "DejaVu Sans Mono",
  "Droid Sans Mono",
  "Everson Mono",
  "Fira Mono",
  "Fira Code",
  "Fixed",
  "Fixedsys",
  "HyperFont",
  "IBM Plex Mono",
  "Inconsolata",
  "Letter Gothic",
  "Liberation Mono",
  "Lucida Console",
  "Lucida Sans Typewriter",
  "Lucida Typewriter",
  "Menlo",
  "Monaco",
  "Monospace",
  "MS Gothic",
  "MS Mincho",
  "Nimbus Mono L",
  "OCR-A",
  "OCR-B",
  "PragmataPro",
  "Prestige Elite",
  "ProFont",
  "Proggy",
  "Proggy Vector",
  "Proggy Original",
  "Roboto Mono",
  "SimHei",
  "SimSun",
  "Source Code Pro",
  "Terminal",
  "Ubuntu Mono",
  "Vera Sans Mono",
  "Bitstream Vera",
  "Vera",
  "Balloon",
  "Brush Script",
  "Choc",
  "Dom Casual",
  "Mistral",
  "Papyrus",
  "Segoe Script",
  "Utopia",
  "American Scribe",
  "AMS Euler",
  "Apple Chancery",
  "Forte",
  "French Script",
  "ITC Zapf Chancery",
  "Kuenstler Script",
  "Monotype Corsiva",
  "Old English Text MT",
  "Zapfino",
  "Andy",
  "Ashley Script",
  "Cézanne",
  "Chalkboard",
  "Comic Sans MS",
  "Comic Neue",
  "Dom Casual",
  "Freestyle Script",
  "Kristen",
  "Lucida Handwriting",
  "Coronet",
  "Curlz",
  "Gravura",
  "Script",
  "Wiesbaden Swing",
  "Bastard",
  "Breitkopf Fraktur",
  "Cloister Black",
  "Fette Fraktur",
  "Fletcher",
  "Fraktur",
  "Lucida Blackletter",
  "Old English Text",
  "Schwabacher",
  "Theuerdank Fraktur",
  "Aharoni",
  "Aldhabi",
  "Aisha",
  "Aparajita",
  "Arek",
  "Arial",
  "Avory",
  "Awami Nastaliq",
  "Baloo",
  "Calibri",
  "Chandas",
  "Clone",
  "Corsair",
  "Eskorte",
  "Gadugi",
  "Grecs du roi",
  "Hanacaraka",
  "Japanese Gothic",
  "Jomolhari",
  "Kiran",
  "Kochi",
  "Koren",
  "Kruti Dev",
  "Malgun Gothic",
  "Meiryo",
  "Microsoft JhengHei",
  "Microsoft YaHei",
  "Minchō",
  "Mincho",
  "Ming",
  "Mona",
  "MS Gothic",
  "Nassim",
  "Nastaliq Navees",
  "Neacademia",
  "Noto Sans",
  "Noto Serif",
  "Perpetua Greek",
  "Porson",
  "Segoe UI Symbol",
  "Shruti",
  "Skolar",
  "Skolar Sans",
  "SimSun",
  "Sylfaen",
  "Sutturah",
  "Tahoma",
  "Tengwar",
  "Tibetan Machine Uni",
  "Urdu Typesetting",
  "Wilson Greek",
  "Apple Symbols (Included with macOS)",
  "Asana-Math",
  "Blackboard bold",
  "Bookshelf Symbol 7",
  "Cambria Math",
  "Computer Modern",
  "Lucida Math",
  "Marlett",
  "Symbol",
  "Webdings",
  "Wingdings",
  "Wingdings",
  "Wingdings",
  "Zapf Dingbats",
  "Ad Lib",
  "Allegro",
  "Andreas",
  "Arnold Böcklin",
  "Astur",
  "Banco",
  "Bauhaus",
  "Braggadocio",
  "Broadway",
  "Caslon Antique",
  "Cooper Black",
  "Curlz",
  "Ellington",
  "Exocet",
  "FIG Script",
  "Forte",
  "Gabriola",
  "Horizon",
  "Jim Crow",
  "Lo-Type",
  "Neuland",
  "Peignot",
  "San Francisco",
  "Stencil",
  "Umbra",
  "Westminster",
  "Willow",
  "Windsor",
  "Lithos",
  "Compatil",
  "Generis",
  "Grasset",
  "LED",
  "Luxi",
  "Sans forgetica",
  "ABeeZee",
  "Abel",
  "Abhaya Libre",
  "Abril Fatface",
  "Aclonica",
  "Acme",
  "Actor",
  "Adamina",
  "Advent Pro",
  "Aguafina Script",
  "Akaya Kanadaka",
  "Akaya Telivigala",
  "Akronim",
  "Aladin",
  "Alata",
  "Alatsi",
  "Aldrich",
  "Alef",
  "Alegreya",
  "Alegreya SC",
  "Alegreya Sans",
  "Alegreya Sans SC",
  "Aleo",
  "Alex Brush",
  "Alfa Slab One",
  "Alice",
  "Alike",
  "Alike Angular",
  "Allan",
  "Allerta",
  "Allerta Stencil",
  "Allura",
  "Almarai",
  "Almendra",
  "Almendra Display",
  "Almendra SC",
  "Amarante",
  "Amaranth",
  "Amatic SC",
  "Amethysta",
  "Amiko",
  "Amiri",
  "Amita",
  "Anaheim",
  "Andada",
  "Andika",
  "Andika New Basic",
  "Angkor",
  "Annie Use Your Telescope",
  "Anonymous Pro",
  "Antic",
  "Antic Didone",
  "Antic Slab",
  "Anton",
  "Antonio",
  "Arapey",
  "Arbutus",
  "Arbutus Slab",
  "Architects Daughter",
  "Archivo",
  "Archivo Black",
  "Archivo Narrow",
  "Aref Ruqaa",
  "Arima Madurai",
  "Arimo",
  "Arizonia",
  "Armata",
  "Arsenal",
  "Artifika",
  "Arvo",
  "Arya",
  "Asap",
  "Asap Condensed",
  "Asar",
  "Asset",
  "Assistant",
  "Astloch",
  "Asul",
  "Athiti",
  "Atma",
  "Atomic Age",
  "Aubrey",
  "Audiowide",
  "Autour One",
  "Average",
  "Average Sans",
  "Averia Gruesa Libre",
  "Averia Libre",
  "Averia Sans Libre",
  "Averia Serif Libre",
  "B612",
  "B612 Mono",
  "Bad Script",
  "Bahiana",
  "Bahianita",
  "Bai Jamjuree",
  "Ballet",
  "Baloo 2",
  "Baloo Bhai 2",
  "Baloo Bhaina 2",
  "Baloo Chettan 2",
  "Baloo Da 2",
  "Baloo Paaji 2",
  "Baloo Tamma 2",
  "Baloo Tammudu 2",
  "Baloo Thambi 2",
  "Balsamiq Sans",
  "Balthazar",
  "Bangers",
  "Barlow",
  "Barlow Condensed",
  "Barlow Semi Condensed",
  "Barriecito",
  "Barrio",
  "Basic",
  "Baskervville",
  "Battambang",
  "Baumans",
  "Bayon",
  "Be Vietnam",
  "Bebas Neue",
  "Belgrano",
  "Bellefair",
  "Belleza",
  "Bellota",
  "Bellota Text",
  "BenchNine",
  "Benne",
  "Bentham",
  "Berkshire Swash",
  "Beth Ellen",
  "Bevan",
  "Big Shoulders Display",
  "Big Shoulders Inline Display",
  "Big Shoulders Inline Text",
  "Big Shoulders Stencil Display",
  "Big Shoulders Stencil Text",
  "Big Shoulders Text",
  "Bigelow Rules",
  "Bigshot One",
  "Bilbo",
  "Bilbo Swash Caps",
  "BioRhyme",
  "BioRhyme Expanded",
  "Biryani",
  "Bitter",
  "Black And White Picture",
  "Black Han Sans",
  "Black Ops One",
  "Blinker",
  "Bodoni Moda",
  "Bokor",
  "Bonbon",
  "Boogaloo",
  "Bowlby One",
  "Bowlby One SC",
  "Brawler",
  "Bree Serif",
  "Brygada 1918",
  "Bubblegum Sans",
  "Bubbler One",
  "Buda",
  "Buenard",
  "Bungee",
  "Bungee Hairline",
  "Bungee Inline",
  "Bungee Outline",
  "Bungee Shade",
  "Butcherman",
  "Butterfly Kids",
  "Cabin",
  "Cabin Condensed",
  "Cabin Sketch",
  "Caesar Dressing",
  "Cagliostro",
  "Cairo",
  "Caladea",
  "Calistoga",
  "Calligraffitti",
  "Cambay",
  "Cambo",
  "Candal",
  "Cantarell",
  "Cantata One",
  "Cantora One",
  "Capriola",
  "Cardo",
  "Carme",
  "Carrois Gothic",
  "Carrois Gothic SC",
  "Carter One",
  "Castoro",
  "Catamaran",
  "Caudex",
  "Caveat",
  "Caveat Brush",
  "Cedarville Cursive",
  "Ceviche One",
  "Chakra Petch",
  "Changa",
  "Changa One",
  "Chango",
  "Charm",
  "Charmonman",
  "Chathura",
  "Chau Philomene One",
  "Chela One",
  "Chelsea Market",
  "Chenla",
  "Cherry Cream Soda",
  "Cherry Swash",
  "Chewy",
  "Chicle",
  "Chilanka",
  "Chivo",
  "Chonburi",
  "Cinzel",
  "Cinzel Decorative",
  "Clicker Script",
  "Coda",
  "Coda Caption",
  "Codystar",
  "Coiny",
  "Combo",
  "Comfortaa",
  "Comic Neue",
  "Coming Soon",
  "Commissioner",
  "Concert One",
  "Condiment",
  "Content",
  "Contrail One",
  "Convergence",
  "Cookie",
  "Copse",
  "Corben",
  "Cormorant",
  "Cormorant Garamond",
  "Cormorant Infant",
  "Cormorant SC",
  "Cormorant Unicase",
  "Cormorant Upright",
  "Courgette",
  "Courier Prime",
  "Cousine",
  "Coustard",
  "Covered By Your Grace",
  "Crafty Girls",
  "Creepster",
  "Crete Round",
  "Crimson Pro",
  "Crimson Text",
  "Croissant One",
  "Crushed",
  "Cuprum",
  "Cute Font",
  "Cutive",
  "Cutive Mono",
  "DM Mono",
  "DM Sans",
  "DM Serif Display",
  "DM Serif Text",
  "Damion",
  "Dancing Script",
  "Dangrek",
  "Darker Grotesque",
  "David Libre",
  "Dawning of a New Day",
  "Days One",
  "Dekko",
  "Dela Gothic One",
  "Delius",
  "Delius Swash Caps",
  "Delius Unicase",
  "Della Respira",
  "Denk One",
  "Devonshire",
  "Dhurjati",
  "Didact Gothic",
  "Diplomata",
  "Diplomata SC",
  "Do Hyeon",
  "Dokdo",
  "Domine",
  "Donegal One",
  "Doppio One",
  "Dorsa",
  "Dosis",
  "DotGothic16",
  "Dr Sugiyama",
  "Duru Sans",
  "Dynalight",
  "EB Garamond",
  "Eagle Lake",
  "East Sea Dokdo",
  "Eater",
  "Economica",
  "Eczar",
  "El Messiri",
  "Electrolize",
  "Elsie",
  "Elsie Swash Caps",
  "Emblema One",
  "Emilys Candy",
  "Encode Sans",
  "Encode Sans Condensed",
  "Encode Sans Expanded",
  "Encode Sans Semi Condensed",
  "Encode Sans Semi Expanded",
  "Engagement",
  "Englebert",
  "Enriqueta",
  "Epilogue",
  "Erica One",
  "Esteban",
  "Euphoria Script",
  "Ewert",
  "Exo",
  "Exo 2",
  "Expletus Sans",
  "Fahkwang",
  "Fanwood Text",
  "Farro",
  "Farsan",
  "Fascinate",
  "Fascinate Inline",
  "Faster One",
  "Fasthand",
  "Fauna One",
  "Faustina",
  "Federant",
  "Federo",
  "Felipa",
  "Fenix",
  "Finger Paint",
  "Fira Code",
  "Fira Mono",
  "Fira Sans",
  "Fira Sans Condensed",
  "Fira Sans Extra Condensed",
  "Fjalla One",
  "Fjord One",
  "Flamenco",
  "Flavors",
  "Fondamento",
  "Fontdiner Swanky",
  "Forum",
  "Francois One",
  "Frank Ruhl Libre",
  "Fraunces",
  "Freckle Face",
  "Fredericka the Great",
  "Fredoka One",
  "Freehand",
  "Fresca",
  "Frijole",
  "Fruktur",
  "Fugaz One",
  "GFS Didot",
  "GFS Neohellenic",
  "Gabriela",
  "Gaegu",
  "Gafata",
  "Galada",
  "Galdeano",
  "Galindo",
  "Gamja Flower",
  "Gayathri",
  "Gelasio",
  "Gentium Basic",
  "Gentium Book Basic",
  "Geo",
  "Geostar",
  "Geostar Fill",
  "Germania One",
  "Gidugu",
  "Gilda Display",
  "Girassol",
  "Give You Glory",
  "Glass Antiqua",
  "Glegoo",
  "Gloria Hallelujah",
  "Goblin One",
  "Gochi Hand",
  "Goldman",
  "Gorditas",
  "Gothic A1",
  "Gotu",
  "Goudy Bookletter 1911",
  "Graduate",
  "Grand Hotel",
  "Grandstander",
  "Gravitas One",
  "Great Vibes",
  "Grenze",
  "Grenze Gotisch",
  "Griffy",
  "Gruppo",
  "Gudea",
  "Gugi",
  "Gupter",
  "Gurajada",
  "Habibi",
  "Hachi Maru Pop",
  "Halant",
  "Hammersmith One",
  "Hanalei",
  "Hanalei Fill",
  "Handlee",
  "Hanuman",
  "Happy Monkey",
  "Harmattan",
  "Headland One",
  "Heebo",
  "Henny Penny",
  "Hepta Slab",
  "Herr Von Muellerhoff",
  "Hi Melody",
  "Hind",
  "Hind Guntur",
  "Hind Madurai",
  "Hind Siliguri",
  "Hind Vadodara",
  "Holtwood One SC",
  "Homemade Apple",
  "Homenaje",
  "IBM Plex Mono",
  "IBM Plex Sans",
  "IBM Plex Sans Condensed",
  "IBM Plex Serif",
  "IM Fell DW Pica",
  "IM Fell DW Pica SC",
  "IM Fell Double Pica",
  "IM Fell Double Pica SC",
  "IM Fell English",
  "IM Fell English SC",
  "IM Fell French Canon",
  "IM Fell French Canon SC",
  "IM Fell Great Primer",
  "IM Fell Great Primer SC",
  "Ibarra Real Nova",
  "Iceberg",
  "Iceland",
  "Imbue",
  "Imprima",
  "Inconsolata",
  "Inder",
  "Indie Flower",
  "Inika",
  "Inknut Antiqua",
  "Inria Sans",
  "Inria Serif",
  "Inter",
  "Irish Grover",
  "Istok Web",
  "Italiana",
  "Italianno",
  "Itim",
  "Jacques Francois",
  "Jacques Francois Shadow",
  "Jaldi",
  "JetBrains Mono",
  "Jim Nightshade",
  "Jockey One",
  "Jolly Lodger",
  "Jomhuria",
  "Jomolhari",
  "Josefin Sans",
  "Josefin Slab",
  "Jost",
  "Joti One",
  "Jua",
  "Judson",
  "Julee",
  "Julius Sans One",
  "Junge",
  "Jura",
  "Just Another Hand",
  "Just Me Again Down Here",
  "K2D",
  "Kadwa",
  "Kalam",
  "Kameron",
  "Kanit",
  "Kantumruy",
  "Karantina",
  "Karla",
  "Karma",
  "Katibeh",
  "Kaushan Script",
  "Kavivanar",
  "Kavoon",
  "Kdam Thmor",
  "Keania One",
  "Kelly Slab",
  "Kenia",
  "Khand",
  "Khmer",
  "Khula",
  "Kirang Haerang",
  "Kite One",
  "Kiwi Maru",
  "Knewave",
  "KoHo",
  "Kodchasan",
  "Kosugi",
  "Kosugi Maru",
  "Kotta One",
  "Koulen",
  "Kranky",
  "Kreon",
  "Kristi",
  "Krona One",
  "Krub",
  "Kufam",
  "Kulim Park",
  "Kumar One",
  "Kumar One Outline",
  "Kumbh Sans",
  "Kurale",
  "La Belle Aurore",
  "Lacquer",
  "Laila",
  "Lakki Reddy",
  "Lalezar",
  "Lancelot",
  "Langar",
  "Lateef",
  "Lato",
  "League Script",
  "Leckerli One",
  "Ledger",
  "Lekton",
  "Lemon",
  "Lemonada",
  "Lexend",
  "Lexend Deca",
  "Lexend Exa",
  "Lexend Giga",
  "Lexend Mega",
  "Lexend Peta",
  "Lexend Tera",
  "Lexend Zetta",
  "Libre Barcode 128",
  "Libre Barcode 128 Text",
  "Libre Barcode 39",
  "Libre Barcode 39 Extended",
  "Libre Barcode 39 Extended Text",
  "Libre Barcode 39 Text",
  "Libre Barcode EAN13 Text",
  "Libre Baskerville",
  "Libre Caslon Display",
  "Libre Caslon Text",
  "Libre Franklin",
  "Life Savers",
  "Lilita One",
  "Lily Script One",
  "Limelight",
  "Linden Hill",
  "Literata",
  "Liu Jian Mao Cao",
  "Livvic",
  "Lobster",
  "Lobster Two",
  "Londrina Outline",
  "Londrina Shadow",
  "Londrina Sketch",
  "Londrina Solid",
  "Long Cang",
  "Lora",
  "Love Ya Like A Sister",
  "Loved by the King",
  "Lovers Quarrel",
  "Luckiest Guy",
  "Lusitana",
  "Lustria",
  "M PLUS 1p",
  "M PLUS Rounded 1c",
  "Ma Shan Zheng",
  "Macondo",
  "Macondo Swash Caps",
  "Mada",
  "Magra",
  "Maiden Orange",
  "Maitree",
  "Major Mono Display",
  "Mako",
  "Mali",
  "Mallanna",
  "Mandali",
  "Manjari",
  "Manrope",
  "Mansalva",
  "Manuale",
  "Marcellus",
  "Marcellus SC",
  "Marck Script",
  "Margarine",
  "Markazi Text",
  "Marko One",
  "Marmelad",
  "Martel",
  "Martel Sans",
  "Marvel",
  "Mate",
  "Mate SC",
  "Maven Pro",
  "McLaren",
  "Meddon",
  "MedievalSharp",
  "Medula One",
  "Meera Inimai",
  "Megrim",
  "Meie Script",
  "Merienda",
  "Merienda One",
  "Merriweather",
  "Merriweather Sans",
  "Metal",
  "Metal Mania",
  "Metamorphous",
  "Metrophobic",
  "Michroma",
  "Milonga",
  "Miltonian",
  "Miltonian Tattoo",
  "Mina",
  "Miniver",
  "Miriam Libre",
  "Mirza",
  "Miss Fajardose",
  "Mitr",
  "Modak",
  "Modern Antiqua",
  "Mogra",
  "Molengo",
  "Molle",
  "Monda",
  "Monofett",
  "Monoton",
  "Monsieur La Doulaise",
  "Montaga",
  "Montez",
  "Montserrat",
  "Montserrat Alternates",
  "Montserrat Subrayada",
  "Moul",
  "Moulpali",
  "Mountains of Christmas",
  "Mouse Memoirs",
  "Mr Bedfort",
  "Mr Dafoe",
  "Mr De Haviland",
  "Mrs Saint Delafield",
  "Mrs Sheppards",
  "Mukta",
  "Mukta Mahee",
  "Mukta Malar",
  "Mukta Vaani",
  "Mulish",
  "MuseoModerno",
  "Mystery Quest",
  "NTR",
  "Nanum Brush Script",
  "Nanum Gothic",
  "Nanum Gothic Coding",
  "Nanum Myeongjo",
  "Nanum Pen Script",
  "Nerko One",
  "Neucha",
  "Neuton",
  "New Rocker",
  "New Tegomin",
  "News Cycle",
  "Newsreader",
  "Niconne",
  "Niramit",
  "Nixie One",
  "Nobile",
  "Nokora",
  "Norican",
  "Nosifer",
  "Notable",
  "Nothing You Could Do",
  "Noticia Text",
  "Noto Sans",
  "Noto Sans HK",
  "Noto Sans JP",
  "Noto Sans KR",
  "Noto Sans SC",
  "Noto Sans TC",
  "Noto Serif",
  "Noto Serif JP",
  "Noto Serif KR",
  "Noto Serif SC",
  "Noto Serif TC",
  "Nova Cut",
  "Nova Flat",
  "Nova Mono",
  "Nova Oval",
  "Nova Round",
  "Nova Script",
  "Nova Slim",
  "Nova Square",
  "Numans",
  "Nunito",
  "Nunito Sans",
  "Odibee Sans",
  "Odor Mean Chey",
  "Offside",
  "Oi",
  "Old Standard TT",
  "Oldenburg",
  "Oleo Script",
  "Oleo Script Swash Caps",
  "Open Sans",
  "Open Sans Condensed",
  "Oranienbaum",
  "Orbitron",
  "Oregano",
  "Orelega One",
  "Orienta",
  "Original Surfer",
  "Oswald",
  "Over the Rainbow",
  "Overlock",
  "Overlock SC",
  "Overpass",
  "Overpass Mono",
  "Ovo",
  "Oxanium",
  "Oxygen",
  "Oxygen Mono",
  "PT Mono",
  "PT Sans",
  "PT Sans Caption",
  "PT Sans Narrow",
  "PT Serif",
  "PT Serif Caption",
  "Pacifico",
  "Padauk",
  "Palanquin",
  "Palanquin Dark",
  "Pangolin",
  "Paprika",
  "Parisienne",
  "Passero One",
  "Passion One",
  "Pathway Gothic One",
  "Patrick Hand",
  "Patrick Hand SC",
  "Pattaya",
  "Patua One",
  "Pavanam",
  "Paytone One",
  "Peddana",
  "Peralta",
  "Permanent Marker",
  "Petit Formal Script",
  "Petrona",
  "Philosopher",
  "Piazzolla",
  "Piedra",
  "Pinyon Script",
  "Pirata One",
  "Plaster",
  "Play",
  "Playball",
  "Playfair Display",
  "Playfair Display SC",
  "Podkova",
  "Poiret One",
  "Poller One",
  "Poly",
  "Pompiere",
  "Pontano Sans",
  "Poor Story",
  "Poppins",
  "Port Lligat Sans",
  "Port Lligat Slab",
  "Potta One",
  "Pragati Narrow",
  "Prata",
  "Preahvihear",
  "Press Start 2P",
  "Pridi",
  "Princess Sofia",
  "Prociono",
  "Prompt",
  "Prosto One",
  "Proza Libre",
  "Public Sans",
  "Puritan",
  "Purple Purse",
  "Quando",
  "Quantico",
  "Quattrocento",
  "Quattrocento Sans",
  "Questrial",
  "Quicksand",
  "Quintessential",
  "Qwigley",
  "Racing Sans One",
  "Radley",
  "Rajdhani",
  "Rakkas",
  "Raleway",
  "Raleway Dots",
  "Ramabhadra",
  "Ramaraja",
  "Rambla",
  "Rammetto One",
  "Ranchers",
  "Rancho",
  "Ranga",
  "Rasa",
  "Rationale",
  "Ravi Prakash",
  "Recursive",
  "Red Hat Display",
  "Red Hat Text",
  "Red Rose",
  "Redressed",
  "Reem Kufi",
  "Reenie Beanie",
  "Reggae One",
  "Revalia",
  "Rhodium Libre",
  "Ribeye",
  "Ribeye Marrow",
  "Righteous",
  "Risque",
  "Roboto",
  "Roboto Condensed",
  "Roboto Mono",
  "Roboto Slab",
  "Rochester",
  "Rock Salt",
  "RocknRoll One",
  "Rokkitt",
  "Romanesco",
  "Ropa Sans",
  "Rosario",
  "Rosarivo",
  "Rouge Script",
  "Rowdies",
  "Rozha One",
  "Rubik",
  "Rubik Mono One",
  "Ruda",
  "Rufina",
  "Ruge Boogie",
  "Ruluko",
  "Rum Raisin",
  "Ruslan Display",
  "Russo One",
  "Ruthie",
  "Rye",
  "Sacramento",
  "Sahitya",
  "Sail",
  "Saira",
  "Saira Condensed",
  "Saira Extra Condensed",
  "Saira Semi Condensed",
  "Saira Stencil One",
  "Salsa",
  "Sanchez",
  "Sancreek",
  "Sansita",
  "Sansita Swashed",
  "Sarabun",
  "Sarala",
  "Sarina",
  "Sarpanch",
  "Satisfy",
  "Sawarabi Gothic",
  "Sawarabi Mincho",
  "Scada",
  "Scheherazade",
  "Schoolbell",
  "Scope One",
  "Seaweed Script",
  "Secular One",
  "Sedgwick Ave",
  "Sedgwick Ave Display",
  "Sen",
  "Sevillana",
  "Seymour One",
  "Shadows Into Light",
  "Shadows Into Light Two",
  "Shanti",
  "Share",
  "Share Tech",
  "Share Tech Mono",
  "Shippori Mincho",
  "Shippori Mincho B1",
  "Shojumaru",
  "Short Stack",
  "Shrikhand",
  "Siemreap",
  "Sigmar One",
  "Signika",
  "Signika Negative",
  "Simonetta",
  "Single Day",
  "Sintony",
  "Sirin Stencil",
  "Six Caps",
  "Skranji",
  "Slabo 13px",
  "Slabo 27px",
  "Slackey",
  "Smokum",
  "Smythe",
  "Sniglet",
  "Snippet",
  "Snowburst One",
  "Sofadi One",
  "Sofia",
  "Solway",
  "Song Myung",
  "Sonsie One",
  "Sora",
  "Sorts Mill Goudy",
  "Source Code Pro",
  "Source Sans Pro",
  "Source Serif Pro",
  "Space Grotesk",
  "Space Mono",
  "Spartan",
  "Special Elite",
  "Spectral",
  "Spectral SC",
  "Spicy Rice",
  "Spinnaker",
  "Spirax",
  "Squada One",
  "Sree Krushnadevaraya",
  "Sriracha",
  "Srisakdi",
  "Staatliches",
  "Stalemate",
  "Stalinist One",
  "Stardos Stencil",
  "Stick",
  "Stint Ultra Condensed",
  "Stint Ultra Expanded",
  "Stoke",
  "Strait",
  "Stylish",
  "Sue Ellen Francisco",
  "Suez One",
  "Sulphur Point",
  "Sumana",
  "Sunflower",
  "Sunshiney",
  "Supermercado One",
  "Sura",
  "Suranna",
  "Suravaram",
  "Suwannaphum",
  "Swanky and Moo Moo",
  "Syncopate",
  "Syne",
  "Syne Mono",
  "Syne Tactile",
  "Tajawal",
  "Tangerine",
  "Taprom",
  "Tauri",
  "Taviraj",
  "Teko",
  "Telex",
  "Tenali Ramakrishna",
  "Tenor Sans",
  "Text Me One",
  "Texturina",
  "Thasadith",
  "The Girl Next Door",
  "Tienne",
  "Tillana",
  "Timmana",
  "Tinos",
  "Titan One",
  "Titillium Web",
  "Tomorrow",
  "Trade Winds",
  "Train One",
  "Trirong",
  "Trispace",
  "Trocchi",
  "Trochut",
  "Truculenta",
  "Trykker",
  "Tulpen One",
  "Turret Road",
  "Ubuntu",
  "Ubuntu Condensed",
  "Ubuntu Mono",
  "Ultra",
  "Uncial Antiqua",
  "Underdog",
  "Unica One",
  "UnifrakturCook",
  "UnifrakturMaguntia",
  "Unkempt",
  "Unlock",
  "Unna",
  "VT323",
  "Vampiro One",
  "Varela",
  "Varela Round",
  "Varta",
  "Vast Shadow",
  "Vesper Libre",
  "Viaoda Libre",
  "Vibes",
  "Vibur",
  "Vidaloka",
  "Viga",
  "Voces",
  "Volkhov",
  "Vollkorn",
  "Vollkorn SC",
  "Voltaire",
  "Waiting for the Sunrise",
  "Wallpoet",
  "Walter Turncoat",
  "Warnes",
  "Wellfleet",
  "Wendy One",
  "Wire One",
  "Work Sans",
  "Xanh Mono",
  "Yanone Kaffeesatz",
  "Yantramanav",
  "Yatra One",
  "Yellowtail",
  "Yeon Sung",
  "Yeseva One",
  "Yesteryear",
  "Yrsa",
  "Yusei Magic",
  "ZCOOL KuaiLe",
  "ZCOOL QingKe HuangYou",
  "ZCOOL XiaoWei",
  "Zen Dots",
  "Zeyada",
  "Zhi Mang Xing",
  "Zilla Slab",
  "Zilla Slab Highlight",
  "Aaux Next",
  "Abadi",
  "Abadi Condensed",
  "Abigail",
  "Abolition",
  "Abolition Soft",
  "Abril Display",
  "Abril Fatface",
  "Abril Text",
  "Abril Titling",
  "Abril Titling Condensed",
  "Abril Titling Narrow",
  "Abril Titling Semicondensed",
  "Acier BAT Text Gris",
  "Acier BAT Text Noir",
  "Acier BAT Text Outline",
  "Acier BAT Text Solid",
  "Acier BAT Text Strokes",
  "Acme Gothic",
  "Acme Gothic Compressed",
  "Acme Gothic Condensed",
  "Acme Gothic Extrawide",
  "Acme Gothic Wide",
  "Acroterion JF",
  "Active",
  "Acumin Pro",
  "Acumin Pro Condensed",
  "Acumin Pro Extra Condensed",
  "Acumin Pro Semi Condensed",
  "Acumin Pro Wide",
  "Acuta",
  "Adage Script JF",
  "Adaptive Mono",
  "Address Sans Pro",
  "Address Sans Pro Condensed",
  "Address Sans Pro Extended",
  "Adelle",
  "Adelle Condensed",
  "Adelle Sans",
  "Adelle Sans Condensed",
  "Adelle Sans Ultra Thin",
  "Adelle Ultrathin",
  "Adhesive Nr. Seven",
  "Adobe Caslon Pro",
  "Adobe Garamond Pro",
  "Adobe Handwriting Ernie",
  "Adobe Handwriting Frank",
  "Adobe Handwriting Tiffany",
  "Adobe Jenson Pro",
  "Adobe Jenson Pro Caption",
  "Adobe Jenson Pro Display",
  "Adobe Jenson Pro Subhead",
  "Adobe Text Pro",
  "Adonis",
  "Adore You",
  "Adore You Slanted",
  "Adorn Banners",
  "Adorn Bouquet",
  "Adorn Catchwords",
  "Adorn Condensed Sans",
  "Adorn Copperplate",
  "Adorn Coronet",
  "Adorn Duo",
  "Adorn Engraved",
  "Adorn Engraved Expanded",
  "Adorn Expanded Sans",
  "Adorn Frames",
  "Adorn Garland",
  "Adorn Ornaments",
  "Adorn Pomander",
  "Adorn Roman",
  "Adorn Serif",
  "Adorn Slab Serif",
  "Adorn Smooth Condensed Sans",
  "Adorn Smooth Engraved",
  "Adorn Smooth Pomander",
  "Adorn Smooth Serif",
  "Adorn Smooth Slab Serif",
  "Adorn Solo",
  "Adorn Trio",
  "Adriane Text",
  "Adrianna",
  "Adrianna Condensed",
  "Adrianna Extended",
  "Adso",
  "Adventure",
  "Aesthet Nova",
  "Agenda",
  "Agenda Condensed",
  "Agenda Extra Condensed",
  "Agenda Ultra Condensed",
  "Aglet Mono",
  "Aglet Sans",
  "Aglet Slab",
  "AGNamsangjun Plain",
  "AGNamsangjun Striped",
  "Aileron",
  "Ainsdale",
  "Ainslie Sans",
  "Ainslie Sans Condensed",
  "Ainslie Sans Extended",
  "Airy",
  "Airy Pictures",
  "Aisha Latin",
  "Akagi Pro",
  "Aktiv Grotesk",
  "Aktiv Grotesk Condensed",
  "Aktiv Grotesk Extended",
  "Aktiv Grotesk Thin",
  "Al Fresco",
  "Alana",
  "Alana Smooth",
  "Alber New Web",
  "Albertan Pro",
  "Albertan Pro Inline",
  "Albiona",
  "Albiona Stencil",
  "Alda",
  "Alegreya",
  "Alegreya Sans",
  "Alegreya Sans SC",
  "Alegreya SC",
  "Alex Brush",
  "Alexa Std",
  "Alexander Quill",
  "Alfarn",
  "Alga",
  "Alhambra",
  "Alibaba Sans",
  "Alisha",
  "Alize",
  "All Round Gothic",
  "Aller",
  "Aller Display",
  "Allotrope",
  "Allotrope Heavy",
  "Allumi Std",
  "Allumi Std Extended",
  "Allura",
  "Almaq Refined",
  "Almaq Rough",
  "Alphatier Pro",
  "Alpine Script",
  "Alta California",
  "Alternate Gothic ATF",
  "Alternate Gothic ATF Thin",
  "Alternate Gothic Compressed ATF",
  "Alternate Gothic Compressed ATF Thin",
  "Alternate Gothic Condensed ATF",
  "Alternate Gothic Condensed ATF Thin",
  "Alternate Gothic Extra Cond ATF",
  "Alternate Gothic Extra Cond ATF Thin",
  "Alternate Gothic No. 1 D",
  "Alternate Gothic No. 2 D",
  "Alternate Gothic No. 3 D",
  "Altivo",
  "Alverata",
  "Alverata Informal",
  "Alverata Irregular",
  "Alwyn New Rounded Web",
  "Alwyn New Web",
  "Amador",
  "Amaranth",
  "Amatic SC",
  "Amboy Black",
  "Amboy Inline",
  "Ambroise Std",
  "Ambroise Std Firmin",
  "Ambroise Std Francois",
  "American Scribe",
  "Americane",
  "Americane Condensed",
  "Amoretta",
  "Amoretta Dark",
  "Anago",
  "Anca",
  "Angie Sans Std",
  "Anisette Std",
  "Anisette Std Petite",
  "Anisette Std Petite 2",
  "Aniuk",
  "Anivers",
  "Anivers SC",
  "Annabelle",
  "Annabelle JF",
  "Anonymous Pro",
  "Antarctican Headline",
  "Antarctican Mono",
  "Antiquarian",
  "Antiquarian Scribe",
  "Antique Olive",
  "Antique Olive Compact",
  "Antique Olive Condensed",
  "Antique Olive Nord",
  "Anton",
  "Anzeigen Grotesk",
  "Anziano",
  "Apertura",
  "Apertura Condensed",
  "Aphrosine",
  "Apolline Std",
  "Apotek",
  "Apotek Comp",
  "Apotek Cond",
  "Apotek Extended",
  "Apotek ExtraCond",
  "Apotek ExtraWide",
  "Apotek Wide",
  "Aragon",
  "Arbitrary",
  "Arboria",
  "Arbotek",
  "Arbotek Rounded",
  "Archetype",
  "Archivo Black",
  "Ardoise Std",
  "Ardoise Std Compact",
  "Ardoise Std Narrow",
  "Ardoise Std Tight",
  "Arek Latin",
  "Argumentum",
  "Aria Text G2",
  "Ariana Pro",
  "Arimo",
  "Arlette",
  "Armada",
  "Armada Compressed",
  "Armada Condensed",
  "Armstrong",
  "Armstrong Swash",
  "Arno Pro",
  "Arno Pro Caption",
  "Arno Pro Display",
  "Arno Pro Small Text",
  "Arno Pro Subhead",
  "Arpona",
  "ArponaSans",
  "Array Mono",
  "Array Proportional",
  "Artigo",
  "Artigo Display",
  "Arvo",
  "Arya Double",
  "Arya Single",
  "Arya Triple",
  "Arya Triple Alt",
  "Arzachel",
  "Asap",
  "Ashemore",
  "Ashemore Condensed",
  "Ashemore Extended",
  "AsimovSans",
  "Asphalt",
  "Asphalt Condensed",
  "Astoria",
  "Astoria Condensed",
  "Astoria Sans",
  "Astoria Sans Condensed",
  "Astounder Squared BB",
  "Astounder Squared LC BB",
  "Athelas",
  "Atocha",
  "Atocha Caps",
  "Atrament Web",
  "Atten New",
  "Atten Round New",
  "Attic Antique",
  "Aunt Mildred MVB",
  "Aunt Mildred MVB Small Caps",
  "Aurea Ultra",
  "Auster",
  "Austin Pen",
  "Auto Pro",
  "Auto Pro 1",
  "Auto Pro 2",
  "Auto Pro 3",
  "Auto Pro Small Caps",
  "Automate",
  "Avalanche",
  "Avalon",
  "Avebury Black",
  "Avebury Inline",
  "Aviano",
  "Aviano Didone",
  "Aviano Flare",
  "Aviano Future",
  "Aviano Royale",
  "Aviano Sans",
  "Aviano Serif",
  "Aviano Slab",
  "Aviano Wedge",
  "Avory I Latin",
  "Avory I PE",
  "AW Conqueror Sans",
  "AW Conqueror Std Carved",
  "AW Conqueror Std Didot",
  "AW Conqueror Std Inline",
  "AW Conqueror Std Slab",
  "Axia",
  "Axia Stencil",
  "Aya Script",
  "Azo Sans",
  "Azo Sans Uber",
  "Azote",
  "Backspacer Round",
  "Backspacer Square",
  "Bad Dog",
  "Badaboom Pro",
  "BadTyp",
  "Bagatela",
  "Baileywick JF Gothic",
  "Baker Signet BT",
  "Balboa",
  "Balboa Condensed",
  "Balboa Extra Condensed",
  "Balboa Plus Fill",
  "Balboa Plus Gradient",
  "Balboa Plus Inline",
  "Balboa Plus Primary",
  "Ballare",
  "Ballers Delight",
  "Ballinger",
  "Ballinger Condensed",
  "Ballinger Mono",
  "Ballinger X-Condensed",
  "Balloon D",
  "Balloon URW",
  "Balloon URW Drop Shadow",
  "Balloon URW Outline",
  "Baltica",
  "Baltica Extra Condensed",
  "Balzano Std",
  "Banana",
  "Banshee Std",
  "Barbieri",
  "Barge",
  "Baroque Text JF",
  "Barricada Pro",
  "Barteldes Small",
  "Base 12 Sans",
  "Base 12 Serif",
  "Base 9 Sans",
  "Base 900 Sans",
  "Base Mono Narrow",
  "Base Mono Wide",
  "Basel Neue",
  "Basic Sans",
  "Baskerville Display PT",
  "Baskerville Poster PT",
  "Baskerville URW",
  "Battery Park",
  "Battlefin",
  "Battleslab",
  "BattleStation",
  "Baucher Gothic URW",
  "Baucher Gothic URW Extended",
  "BC Alphapipe",
  "BC Liguria",
  "BC Mikser",
  "BC Novatica CYR",
  "BC Rebecca Grim",
  "BC Rebecca Obsolete",
  "BC Rebecca Ordinary",
  "BC Rebecca Sharp",
  "BC Sklonar",
  "BD Brick",
  "BD Colonius",
  "BD Geminis",
  "BD Jupiter",
  "BD Jupiter Stencil",
  "BD Kickrom Mono",
  "BD Nippori",
  "BD Plakatbau",
  "BD Retrocentric",
  "BD Viewmaster",
  "BDR A3MIK",
  "BDR Mono",
  "BD_MicronFont",
  "Beastly",
  "Bebas Kai",
  "Bebas Neue",
  "Bebas Neue by Fontfabric",
  "Bebas Neue Pro",
  "Bebas Neue Pro Expanded",
  "Bebas Neue Pro SemiExpanded",
  "Bebas Neue Rounded",
  "Bebas Neue SemiRounded",
  "Bebas Neue v1.4 (deprecated)",
  "Becker Gothics Concave",
  "Becker Gothics Egyptian",
  "Becker Gothics Egyptian Rounded",
  "Becker Gothics Stencil",
  "Becker Gothics Tuscan",
  "Bee",
  "Belda Condensed",
  "Belda Extended",
  "Belda Normal",
  "Bell Centennial Std Address",
  "Bell Centennial Std Bold Listing",
  "Bell Centennial Std Name & Number",
  "Bell Centennial Std Sub-Caption",
  "Bello",
  "Bello Caps",
  "Beloved Ornaments",
  "Beloved Sans",
  "Beloved Script",
  "Bely",
  "Bely Display",
  "Bennet Banner",
  "Bennet Banner Condensed",
  "Bennet Banner Extra Condensed",
  "Bennet Display",
  "Bennet Display Condensed",
  "Bennet Display Extra Condensed",
  "Bennet Text Four",
  "Bennet Text One",
  "Bennet Text Three",
  "Bennet Text Two",
  "Bernhard Gothic URW",
  "Bickham Script Pro 3",
  "Bicyclette",
  "BigCheese OT",
  "BigCheese OT Dark",
  "BigCity Grotesque Pro",
  "Bigfish",
  "BigSmalls Bold",
  "BigSmalls Regular",
  "Bilo",
  "Bio Sans",
  "Birch Std",
  "Birra",
  "Birra 2",
  "Birra Lambic",
  "Birra Pils",
  "Bistro Script",
  "Bitter",
  "Black Beard",
  "Blackcurrant",
  "Blackcurrant Cameo",
  "Blackcurrant Squash",
  "Blackoak Std",
  "Blakely",
  "Blambot Casual",
  "Blambot FX Pro",
  "Blambot Pro",
  "Blenny",
  "Bligh",
  "Blockhead Black Face",
  "Blockhead Dark Side",
  "Blockhead Illust OT",
  "Blockhead Illust OT BlackFace",
  "Blockhead Illust OT DarkSide",
  "Blockhead Illust OT Unplugged",
  "Blockhead Plain",
  "Blockhead Unplugged",
  "Blonde Fraktur",
  "Blow Up",
  "Blue Goblet",
  "Blue Goblet Alternate One",
  "Bodedo",
  "Bodega Sans",
  "Bodega Serif",
  "Bodoni URW",
  "Bombarda",
  "Bookeyed Jack",
  "Bookeyed Martin",
  "Bookeyed Nelson",
  "Bookeyed Sadie",
  "Bookeyed Suzanne",
  "Bookman JF Pro",
  "Bookmania",
  "Boreal",
  "Bossa Nova MVB",
  "Botanika Mono Web",
  "Botanika Web",
  "Boucherie Block",
  "Boucherie Cursive",
  "Boucherie Flared",
  "Boucherie Sans",
  "Boucherie Sans Inline",
  "Bouffant",
  "Bounce Script",
  "Bourbon",
  "Bovine MVB",
  "Bovine Round MVB",
  "Bowman",
  "Braisetto",
  "Brandon Grotesque",
  "Breakers",
  "Breakers Slab",
  "Bree",
  "Bree Serif",
  "Bremen",
  "Bressay",
  "Bressay Display",
  "Brevia",
  "Brevier",
  "Brigade",
  "Briller",
  "Brim Narrow",
  "Brim Narrow Combined 1",
  "Brim Narrow Combined 2",
  "Brim Narrow Combined 3",
  "Brioso Pro",
  "Brioso Pro Caption",
  "Brioso Pro Display",
  "Brioso Pro Poster",
  "Brioso Pro Subhead",
  "Brix Slab",
  "Brix Slab Condensed",
  "Broadacre Hairline 0",
  "Broadacre Hairline 1",
  "Broadacre Hairline 2",
  "Broadacre Hairline 3",
  "Broadacre Hairline 4",
  "Broadacre Regular 0",
  "Broadacre Regular 1",
  "Broadacre Regular 2",
  "Broadacre Regular 3",
  "Broadacre Regular 4",
  "Broadacre Thin 0",
  "Broadacre Thin 1",
  "Broadacre Thin 2",
  "Broadacre Thin 3",
  "Broadacre Thin 4",
  "Broadsheet",
  "Bronson Gothic JF",
  "Brother 1816",
  "Brother 1816 Printed",
  "Brother XL",
  "Brother XS",
  "Brothers",
  "Brothers Word Logos",
  "Brush ATF",
  "Brush Script Std",
  "Brushland",
  "Bubblegum Pop Highlight",
  "Bubblegum Pop Shadow",
  "Bubblegum Pop Vanilla",
  "Buckboard Pro",
  "Buckley",
  "Buena Park JF",
  "Bufalino",
  "Buffet Script",
  "Bungee",
  "Bunyan Pro",
  "Burnaby Stencil",
  "Byker",
  "Cabazon",
  "Cabrito Condensed",
  "Cabrito Didone Condensed",
  "Cabrito Didone Extended",
  "Cabrito Didone Normal",
  "Cabrito Extended",
  "Cabrito Normal",
  "Cabrito Sans",
  "Cadogan",
  "Caecilia",
  "Caecilia Sans Head",
  "Caecilia Sans Text",
  "Cafe Mimi MVB",
  "Cafeteria",
  "Caflisch Script Pro",
  "Cairo",
  "Calder Dark",
  "Calder Dark Grit",
  "Calder Dark Grit Shadow",
  "Calder Dark Outline",
  "Calder Dark Shadow",
  "Calder Grit Shadow",
  "Calder LC",
  "Calder LC Grit",
  "Calder Script",
  "Calder Shadow",
  "Calendula",
  "Calgary Script OT",
  "Caliban Std",
  "Calibri",
  "Calling Code",
  "Calliope MVB",
  "Calluna",
  "Calluna Sans",
  "Calmetta",
  "Campaign",
  "Campaign Slab",
  "Cantarell",
  "Cantiga",
  "Cantiga Condensed",
  "Canto",
  "Canto Brush",
  "Canto Brush Open",
  "Canto Pen",
  "Cantoni Pro",
  "Capellina Bold Caps",
  "Capellina Caps",
  "Capellina Rough",
  "Capellina Script",
  "Capitol",
  "Capitolina",
  "Capitolium 2",
  "Capitolium News 2",
  "Caprizant",
  "Captain Comic",
  "Capucine Basic",
  "Carbon",
  "Carbon Fence",
  "Carbon Phyber",
  "Cardea",
  "Cardea Lining",
  "Cardigan",
  "Cardo",
  "Carilliantine",
  "CarlMarx",
  "Carol Gothic",
  "Carta Marina",
  "Casablanca URW",
  "Casey",
  "Catalpa",
  "Catamaran",
  "Catnip",
  "Catseye",
  "Catseye Narrow",
  "Catwing",
  "CCBiffBamBoom",
  "CCBiffBamBoomOutline",
  "CCClobberinTimeCrunchy",
  "CCClobberinTimeSmooth",
  "CCMeanwhile",
  "CCMonsterMash",
  "CCMonsterMashOutline",
  "CCMonsterMashWorn",
  "CCSignLanguage",
  "CCSignLanguageScreamers",
  "Celestia Antiqua MVB",
  "Celestia Antiqua MVB Adornado",
  "Celestia Antiqua MVB Inline",
  "Celestia Antiqua MVB Small Caps",
  "Century Gothic",
  "Century Old Style Std",
  "Chainprinter",
  "Chalfont",
  "Chalky",
  "Chaloops",
  "Chandler 42",
  "Changeling Neo",
  "Changeling Neo Inline",
  "Changeling Neo Stencil",
  "Chanson d'Amour",
  "Chantal",
  "Chaparral Pro",
  "Chaparral Pro Caption",
  "Chaparral Pro Display",
  "Chaparral Pro Subhead",
  "Chapman",
  "Chapman Condensed",
  "Chapman Extended",
  "Charbroiled",
  "Charcuterie Block",
  "Charcuterie Catchwords",
  "Charcuterie Contrast",
  "Charcuterie Cursive",
  "Charcuterie Deco",
  "Charcuterie Engraved",
  "Charcuterie Etched",
  "Charcuterie Filigree",
  "Charcuterie Flared",
  "Charcuterie Frames",
  "Charcuterie Ornaments",
  "Charcuterie Sans",
  "Charcuterie Sans Inline",
  "Charcuterie Serif",
  "Charlemagne Std",
  "Chauncy Pro",
  "Cheap Pine",
  "Cheap Pine Sans",
  "Cheap Pine Shadow",
  "Cheee",
  "Cheee Bingbong",
  "Cheee Boogy",
  "Cheee Jimbo",
  "Cheee Oldskool",
  "Cheee Peeenutt",
  "Cheee Tbone",
  "Cheee Wowie",
  "Chelsea Market Open",
  "Chelsea Market Pro",
  "Chennai",
  "Chennai Slab",
  "Chevin Pro",
  "Chiavettieri",
  "Chinchilla",
  "Chinese Rocks",
  "Chippewa Falls",
  "Cholla Sans",
  "Cholla Slab",
  "Cholla Unicase",
  "Cholla Wide",
  "Chowdown",
  "Chuck",
  "Chunder",
  "Chypre Condensed",
  "Chypre Extended",
  "Chypre Normal",
  "Ciabatta",
  "Cinque Donne",
  "Cinque Donne Pro",
  "Circe",
  "Circe Rounded",
  "Circe Slab A",
  "Circe Slab A Narrow",
  "Circe Slab B",
  "Circe Slab B Narrow",
  "Circe Slab C",
  "Circe Slab C Narrow",
  "Citadel Inline",
  "Citadel Solid",
  "Citizen",
  "Civane Condensed",
  "Civane Extended",
  "Civane Normal",
  "Clarendon Text Pro",
  "Clarendon URW",
  "Clarendon URW Extra Narrow",
  "Clarendon URW Extra Wide",
  "Clarendon Wide",
  "Clarendon Wide Stencil",
  "Classic Comic",
  "Classica Pro",
  "Classico URW",
  "Classico URW Condensed",
  "Clavo",
  "Click Clack",
  "Clicker",
  "Clicker Compressed",
  "Clicker Condensed",
  "Clone Rounded Latin",
  "Clone Rounded PE",
  "Co Headline",
  "Co Text",
  "Code Saver",
  "Coldsmith Pro",
  "Collector Comic",
  "Collier",
  "Collier Black",
  "Colosseum",
  "Colt",
  "Colt Soft",
  "Columbia Titling",
  "Comenia Sans Web",
  "Comic Serif Pro",
  "Commerce",
  "Commuters Sans",
  "Como",
  "Compendium",
  "Concave Tuscan",
  "Conceal",
  "Condor",
  "Condor Compressed",
  "Condor Condensed",
  "Condor Extended",
  "Condor Wide",
  "Congenial",
  "Congenial Light",
  "Conglomerate",
  "Coniferous",
  "Continuo",
  "Coolvetica",
  "Cooper Black Std",
  "Copacabana",
  "Copal Std Decorated",
  "Copal Std Outline",
  "Copal Std Solid",
  "Copperplate",
  "Copperplate Condensed",
  "Copse",
  "Coquette",
  "Cora",
  "Coranto 2",
  "Coranto 2 Headline",
  "Cordale",
  "Cormorant Garamond",
  "Corn Dog",
  "Corner Store JF",
  "Coronette",
  "Corporate A",
  "Corporate A Condensed",
  "Corporate A Std URW",
  "Corporate A Std URW Cond",
  "Corporate E",
  "Corporate E Std URW",
  "Corporate S",
  "Corporate S Std URW",
  "Corpulent Web",
  "Cortado",
  "Corundum Text",
  "Corundum Text SC",
  "Cosmopolitan",
  "Costa Std",
  "Cotton",
  "Cottonwood Std",
  "Council",
  "Council Word Logos",
  "Countach",
  "Courier Prime",
  "Courier Std",
  "Covik Sans",
  "Covik Sans Mono",
  "Cowboyslang",
  "Cowboyslang Condensed",
  "Cowboyslang Expanded",
  "Crackly",
  "Crackly Lines 60",
  "Crassula",
  "Craw Modern URW",
  "Cresta",
  "Crete",
  "Crete Rounded",
  "Crimson",
  "Critter Std",
  "Cronos Pro",
  "Cronos Pro Caption",
  "Cronos Pro Display",
  "Cronos Pro Subhead",
  "Cubano",
  "Cubano Sharp",
  "Cuisine",
  "Curve",
  "Custard",
  "Custard Condensed",
  "Cy",
  "Dalliance Flourishes",
  "Dalliance Roman",
  "Dalliance Script",
  "Dalliance Script Display",
  "Dapifer",
  "Dapifer Stencil",
  "Darkmode Off",
  "Darkmode On",
  "Davis",
  "Davis Sans",
  "Dazzle Unicase",
  "DDT",
  "De Walpergen's Pica",
  "De Walpergen's Pica Small Caps",
  "Dead History",
  "Dederon Sans Web",
  "Dederon Serif Web",
  "Dedica",
  "Default Gothic",
  "Degular",
  "Degular Display",
  "Degular Text",
  "DejaRip",
  "Delaney",
  "Delicato",
  "DeLittle Chromatic",
  "DeLittle Chromatic Inlay",
  "DeLittle Chromatic Outline",
  "Delve Hand",
  "Democratica",
  "Demos Next",
  "Demos Next Condensed",
  "Depot New Condensed Web",
  "Depot New Web",
  "Despina Pro",
  "Despina Pro Alternates",
  "Dessert Script",
  "Deva Ideal",
  "Dharma Gothic C",
  "Dharma Gothic E",
  "Dharma Gothic M",
  "Diavlo",
  "Diazo MVB Cond",
  "Diazo MVB Ex Cond",
  "Diazo MVB Rough1 Cond",
  "Diazo MVB Rough1 Ex Cond",
  "Diazo MVB Rough2 Cond",
  "Diazo MVB Rough2 Ex Cond",
  "Dico Code One",
  "Dico Code Two",
  "Dico Mono",
  "Dico Mono Script",
  "Dico Mono Slab",
  "Dico Sans",
  "Dico Sans Soft",
  "Dico Slab",
  "Dico Typewriter",
  "Dico Typewriter Script",
  "Dico Typewriter Slab",
  "DicSans",
  "DicSans UltraBlack",
  "Didoni URW",
  "Dienstag",
  "Dimensions",
  "DIN 1451 LT Pro Engschrift",
  "DIN 2014",
  "DIN 2014 Narrow",
  "DIN Condensed",
  "DINosaur",
  "Dirtstorm",
  "Dirty Bakers Dozen",
  "Dirty Bakers Dozen Scorch",
  "Dirty Bakers Dozen Spraypaint",
  "Discourse Middle",
  "Discourse Middle Fill",
  "Discourse Middle Outline",
  "Discourse Middle Shadow",
  "Discourse Narrow",
  "Discourse Narrow Fill",
  "Discourse Narrow Outline",
  "Discourse Narrow Shadow",
  "Discourse Wide",
  "Discourse Wide Fill",
  "Discourse Wide Outline",
  "Discourse Wide Shadow",
  "District Pro",
  "Dita",
  "Dita Cd",
  "Dita Wd",
  "Ditch",
  "Divenire",
  "Divulge",
  "Dogma",
  "Dogma Extra Outline",
  "Dogma Outline",
  "Dogma Script",
  "Dolce",
  "Dolly",
  "Dolly Small Caps",
  "DomLovesMary Pro",
  "DomLovesMary Text",
  "Domyouji",
  "Donguri",
  "Double Pica",
  "Double Pica Small Caps",
  "Dovetail MVB",
  "Downtempo",
  "Dr Sugiyama Pro",
  "DrCarbfred Pro",
  "DreamTeam",
  "DreamTeam Thicker",
  "Droid Sans",
  "Droid Sans Mono",
  "Droid Serif",
  "Drunk Cowboy",
  "Dry Cowboy",
  "Duality",
  "Duality Sand",
  "Duality Steel",
  "Dublin",
  "Duende Pro",
  "Dulcian Condensed",
  "Dulcian Extended",
  "Dulcian Normal",
  "Dunbar Low",
  "Dunbar Tall",
  "Dunbar Text",
  "Duos Brush",
  "Duos Paint",
  "Duos Round",
  "Duos Sharp",
  "Duper",
  "Dutch Mediaeval Pro",
  "Dystopian",
  "EB Garamond",
  "Ebony",
  "Eckmannpsych Large",
  "Eckmannpsych Medium",
  "Eckmannpsych Small",
  "Eco",
  "Economica",
  "Ecru",
  "Edding 780",
  "Edita",
  "Edita Small",
  "Eds Market Bold",
  "Eds Market Bold Script",
  "Eds Market Bold Slant",
  "Eds Market Design Elements",
  "Eds Market Main Script",
  "Eds Market Narrow",
  "Eds Market Narrow Slant",
  "Eds Market Regular",
  "Eds Market Regular Slant",
  "Eds Market Upright Script",
  "Eds Market Wide",
  "Effra",
  "Egizio URW",
  "Egizio URW Condensed",
  "Eidetic Neo",
  "Eidetic Neo Omni",
  "Eigerdals",
  "Eldwin Script",
  "Elektrix",
  "Elena Basic",
  "Elevon",
  "Elfreth",
  "Elido",
  "Elido Deco Initials",
  "Elido Initials",
  "Elido Ornaments",
  "Elido Upright Italic",
  "Elina",
  "Elina Decor",
  "Elliotts Blue EyeShadow",
  "Elliotts Jigsaw Dropshadow",
  "Elliotts Typhoid Mary 3D Dark",
  "Elliotts Typhoid Mary 3D Light",
  "Elliotts Venus D Outlined",
  "Elliotts Venus Dioxide",
  "Eloquent JF Pro",
  "Eloquent JF Small Caps Pro",
  "Embarcadero MVB Pro",
  "Embarcadero MVB Pro Condensed",
  "Embryo",
  "Embryo Open",
  "Embury Text",
  "Emily Austin",
  "Emmascript MVB Std",
  "Emploi Ingenue",
  "Emploi Travesti",
  "Enchanted",
  "English",
  "English Grotesque",
  "English Small Caps",
  "Epitaph",
  "Erbaum",
  "Escoffier Capitaux",
  "Eskapade",
  "Eskapade Fraktur",
  "Eskorte Latin",
  "Essay Text",
  "Essential PragmataPro",
  "Essonnes Display",
  "Essonnes Headline",
  "Essonnes Text",
  "Ethnocentric",
  "Etna",
  "Etna Condensed",
  "Europa",
  "Eurostile",
  "Eurostile Condensed",
  "Eurostile Extended",
  "Exo Soft",
  "Exocet",
  "Expo Sans Pro",
  "Expo Sans Pro Condensed",
  "Expo Serif Pro",
  "Export",
  "Export Stencil",
  "Expressway",
  "Facto",
  "Factoria",
  "Fairplex Narrow",
  "Fairplex Wide",
  "Fairwater Deco Serif",
  "Fairwater Open Serif",
  "Fairwater Sailor Serif",
  "Fairwater Sans",
  "Fairwater Script",
  "Fairwater Solid Serif",
  "Fairway",
  "Fairweather",
  "Fairy Tale JF",
  "Fakir",
  "Fakir Display",
  "Fakir Display Condensed",
  "Fakir Display Small Caps",
  "Fakir Small Caps",
  "Falcon Script",
  "Fantabular MVB",
  "Fantabular Sans MVB",
  "Fanwood",
  "Faricy New Web",
  "Farm New",
  "Farmhand",
  "Farmhand Sans",
  "Fatboy",
  "FatFrank",
  "Fave Condensed Pro",
  "Fave Script Pro",
  "Felt Tip Roman",
  "Felt Tip Senior",
  "Felt Tip Woman",
  "Feltro Eroded",
  "Feltro Normal",
  "Feltro Outline",
  "Feltro Outline Texture",
  "Feltro Shadow",
  "Feltro Shadow Texture",
  "Feltro Texture 1",
  "Feltro Texture 2",
  "Feltro Texture 3",
  "Fenway Park JF",
  "Fenwick",
  "Fenwick Outline",
  "Fertigo Pro",
  "Fertigo Pro Script",
  "FF Amman Sans Pro",
  "FF Amman Serif Pro",
  "FF Angie Open Pro",
  "FF Angie Pro",
  "FF Attribute Mono",
  "FF Attribute Text",
  "FF Avance Pro",
  "FF Basic Gothic Pro",
  "FF Basic Gothic SC Web Pro",
  "FF Brokenscript Web",
  "FF Brokenscript Web Condensed",
  "FF Carina",
  "FF Casus",
  "FF Chambers Sans Web",
  "FF Cocon Pro",
  "FF Cocon Pro Condensed",
  "FF Cocon Pro Extra Condensed",
  "FF Dagny Pro",
  "FF Dax Compact Pro",
  "FF Dax Pro",
  "FF Dax Pro Condensed",
  "FF Dax Pro Wide",
  "FF Enzo",
  "FF Ernestine Pro",
  "FF Folk",
  "FF Folk Rough",
  "FF Ginger Pro",
  "FF Good Headline Condensed Pro",
  "FF Good Headline Pro",
  "FF Good Headline Pro Compressed",
  "FF Good Headline Pro Condensed",
  "FF Good Headline Pro Extended",
  "FF Good Headline Pro Extra Condensed",
  "FF Good Headline Pro Narrow",
  "FF Good Headline Pro Wide",
  "FF Good Pro",
  "FF Good Pro Compressed",
  "FF Good Pro Condensed",
  "FF Good Pro Extended",
  "FF Good Pro Extra Condensed",
  "FF Good Pro Narrow",
  "FF Good Pro Wide",
  "FF Info Correspondence Pro",
  "FF Info Display Pro",
  "FF Info Text Pro",
  "FF Karbid Display Pro",
  "FF Karbid Pro",
  "FF Karbid Slab Pro",
  "FF Karbid Text Pro",
  "FF Kava Pro",
  "FF Mach Pro",
  "FF Mach Pro Condensed",
  "FF Mach Wide Pro",
  "FF Market Pro",
  "FF Market Pro Condensed",
  "FF Meta Correspondence Pro",
  "FF Meta Headline Pro",
  "FF Meta Headline Pro Compact",
  "FF Meta Headline Pro Condensed",
  "FF Meta Pro",
  "FF Meta Pro Condensed",
  "FF Meta SC Web Pro",
  "FF Meta Serif Pro",
  "FF Meta Serif SC Web Pro",
  "FF More Pro",
  "FF More Pro Condensed",
  "FF More Pro Wide",
  "FF Nexus Mix",
  "FF Nexus Sans",
  "FF Nexus Serif",
  "FF Nexus Typewriter",
  "FF Nort",
  "FF Nuvo Mono Pro",
  "FF Nuvo Pro",
  "FF Nuvo SC Web Pro",
  "FF Prater Block",
  "FF Prater Block Background Pro",
  "FF Prater Block Fill Pro",
  "FF Prater Sans",
  "FF Prater Script",
  "FF Prater Serif",
  "FF Providence Pro",
  "FF Providence Sans Pro",
  "FF Providence SC Web Pro",
  "FF Real Headline Pro",
  "FF Real Headline Pro 2",
  "FF Real Text Pro",
  "FF Real Text Pro 2",
  "FF Scala",
  "FF Scala Condensed",
  "FF Scala Jewel Crystal",
  "FF Scala Jewel Diamond",
  "FF Scala Jewel Pearl",
  "FF Scala Jewel Saphyr",
  "FF Scala Sans Pro",
  "FF Scala Sans Pro Condensed",
  "FF Seria",
  "FF Seria Sans",
  "FF Sizmo",
  "FF Sizmo Line",
  "FF Speak Pro",
  "FF Spinoza Pro",
  "FF Tisa Pro",
  "FF Tisa Sans Pro",
  "FF Typestar OCR Pro",
  "FF Typestar Pro",
  "FF Uberhand Pro",
  "FF Uberhand Pro 2",
  "FF Uberhand Text Pro",
  "FF Uberhand UI",
  "FF Unit",
  "FF Unit Rounded",
  "FF Unit Slab",
  "FF Utility Pro",
  "FF Zwo Corr Pro",
  "FF Zwo Pro",
  "Fieldwork",
  "Fieldwork Hum",
  "Fight to the Finish BB",
  "Filicudi Solid",
  "Filicudi Striped",
  "Filosofia",
  "Filosofia All Small Caps",
  "Filosofia Basic Small Caps",
  "Filosofia Grand",
  "Filosofia Grand All Small Caps",
  "Filosofia Grand Basic Small Caps",
  "Filosofia Lining",
  "Filosofia Unicase",
  "Filson Pro",
  "Filson Soft",
  "Fino",
  "Fino Sans",
  "Fira Mono",
  "Fira Sans",
  "Fira Sans 2",
  "Fira Sans Compressed",
  "Fira Sans Compressed 2",
  "Fira Sans Condensed",
  "Fira Sans Condensed 2",
  "Fit",
  "Fit Compressed",
  "Fit Condensed",
  "Fit Extended",
  "Fit Extra Condensed",
  "Fit Extra Extended",
  "Fit Extra Wide",
  "Fit Skyline",
  "Fit Ultra Extended",
  "Fit Wide",
  "Flegrei",
  "Fleisch Wolf",
  "Fleisch Wurst",
  "Flood Std",
  "Flower Power",
  "FLW Eaglefeather",
  "Flyswim",
  "Foco",
  "Force",
  "Force Dingbats",
  "Force Shadow",
  "Forgotten Futurist Shadow",
  "Forma DJR Banner",
  "Forma DJR Deck",
  "Forma DJR Display",
  "Forma DJR Micro",
  "Forma DJR Text",
  "FP Dancer Pro",
  "FP Dancer Serif",
  "FP Head Pro",
  "Fragile Bombers",
  "Fragile Bombers Attack",
  "Fragile Bombers Down",
  "Frank New",
  "Franklin Gothic ATF",
  "Franklin Gothic Compressed",
  "Franklin Gothic Condensed",
  "Franklin Gothic Extra Compressed",
  "Franklin Gothic URW",
  "Franklin Gothic URW Compressed",
  "Franklin Gothic URW Condensed",
  "Franklin Gothic URW Extra Compressed",
  "Fredericka the Greatest",
  "Freehouse",
  "Freehouse Rough",
  "Freehouse Wide",
  "Freight Big Pro",
  "Freight Display Pro",
  "Freight Macro Pro",
  "Freight Micro Pro",
  "Freight Neo Pro",
  "Freight Sans Compressed Pro",
  "Freight Sans Compressed Pro Lights",
  "Freight Sans Compressed Pro Ultra",
  "Freight Sans Condensed Pro",
  "Freight Sans Condensed Pro Lights",
  "Freight Sans Condensed Pro Ultra",
  "Freight Sans Pro",
  "Freight Sans Pro Lights",
  "Freight Sans Pro Ultra",
  "Freight Text Pro",
  "FreightBigCmp Pro",
  "FreightDispCmp Pro",
  "FreightNeoCnd Pro",
  "FreightTextCmp Pro",
  "French Canon",
  "French Canon Small Caps",
  "French Clarendon Ornamented",
  "French Octagon",
  "French Roast",
  "Fresno Black",
  "Fresno Inline",
  "Freude",
  "Funkydori",
  "Fusaka Std",
  "Futura PT",
  "Futura PT Bold",
  "Futura PT Condensed",
  "Gala",
  "Gala Biline",
  "Gala Condensed",
  "Gala Extra Condensed",
  "Gala Shadow",
  "Gala Triline",
  "Galahad Std",
  "Galette",
  "Ganache",
  "Garage Gothic",
  "Garamond ATF Micro",
  "Garamond ATF Subhead",
  "Garamond ATF Text",
  "Garamond Premier Pro",
  "Garamond Premier Pro Caption",
  "Garamond Premier Pro Display",
  "Garamond Premier Pro Subhead",
  "Garvis Pro",
  "Gastromond",
  "Gaultier",
  "Gaultier Lights",
  "Gautreaux",
  "Gelo",
  "Gemeli Mono",
  "Geneo Std",
  "Geographica",
  "Geographica Hand",
  "Geom Graphic",
  "Germania One",
  "Gesta",
  "Gibbs",
  "Gibson",
  "Giddyup Std",
  "Gigalypse",
  "Gilbert",
  "Gilbert Color",
  "Gill Sans Nova",
  "Gill Sans Nova Condensed",
  "Gill Sans Nova Deco",
  "Gill Sans Nova Extra Condensed",
  "Gill Sans Nova Inline",
  "Gill Sans Nova Inline Condensed",
  "Gill Sans Nova Shadowed",
  "Gill Sans Nova Shadowed Outline",
  "Gimlet Display",
  "Gimlet Display Compressed",
  "Gimlet Display Condensed",
  "Gimlet Display Narrow",
  "Gimlet Micro",
  "Gimlet Micro Compressed",
  "Gimlet Micro Condensed",
  "Gimlet Micro Narrow",
  "Gimlet Text",
  "Gimlet Text Compressed",
  "Gimlet Text Condensed",
  "Gimlet Text Narrow",
  "Gin",
  "Gineso",
  "Gineso Condensed",
  "Gineso Extended",
  "Gioviale",
  "Gira Sans",
  "Gitan Latin",
  "Giulia",
  "Giulia Plain",
  "Givry",
  "Gizmo",
  "Gloria Hallelujah",
  "Gloss Drop",
  "Gnuolane",
  "Goldenbook",
  "Gomme Sans",
  "Good Bad Man",
  "Good Kitty",
  "Good Times",
  "Good Times Bad Times",
  "GoodDog New",
  "Goodlife Brush",
  "Goodlife Extras",
  "Goodlife Sans",
  "Goodlife Sans Condensed",
  "Goodlife Script",
  "Goodlife Serif",
  "Gopher",
  "Gothic Open Shaded",
  "Gothicus",
  "Gothicus Roman",
  "Goudy Bookletter 1911",
  "Goudy Old Style",
  "Grad",
  "Graffiti",
  "Grafolita Script",
  "Grand Central",
  "Grandma",
  "Grange",
  "Grange Condensed",
  "Grange Extended",
  "Granville",
  "Graphie",
  "Graphite Std",
  "Graphite Std Narrow",
  "Graphite Std Wide",
  "Gratitude Script Pro",
  "Gratitude Smooth Script Pro",
  "Graveblade",
  "Gravesend Sans",
  "Great Primer",
  "Great Primer Small Caps",
  "Great Vibes",
  "Grecian Light Face",
  "Grenadine MVB",
  "Greymantle MVB",
  "Greymantle MVB Extras",
  "Griffith Gothic",
  "Griffith Gothic Condensed",
  "Griffon",
  "Grover",
  "Grover SC",
  "Grover Slab",
  "Grover Slab Caps",
  "Grueber",
  "Gryphius MVB",
  "Gryphius MVB Small Caps",
  "Guanabara Sans",
  "Guapa",
  "Gunplay",
  "Gunplay Damage",
  "Gunplay Spraypaint",
  "Gurkner-Jump",
  "Gyparody",
  "Haboro",
  "Haboro Condensed",
  "Haboro Contrast Condensed",
  "Haboro Contrast Extended",
  "Haboro Contrast Normal",
  "Haboro Extended",
  "Haboro Serif",
  "Haboro Serif Condensed",
  "Haboro Serif Extended",
  "Haboro Soft",
  "Haboro Soft Condensed",
  "Haboro Soft Extended",
  "Hachura",
  "Hack",
  "Hackman",
  "Halcom",
  "Halogen",
  "Halyard Display",
  "Halyard Micro",
  "Halyard Text",
  "Handel Gothic",
  "Harfang Pro",
  "Harlean",
  "Harri",
  "Harri Text",
  "Harvester",
  "Hatch",
  "Hayate",
  "Headlight",
  "Headline Gothic ATF",
  "Headline Gothic ATF Rough No.1",
  "Headline Gothic ATF Rough No.2",
  "Headline Gothic ATF Round",
  "Hedley New Web",
  "Heebo",
  "Heimat Didone 10",
  "Heimat Didone 12",
  "Heimat Didone 14",
  "Heimat Didone 16",
  "Heimat Didone 18",
  "Heimat Didone 20",
  "Heimat Display 10",
  "Heimat Display 12",
  "Heimat Display 14",
  "Heimat Display 16",
  "Heimat Display 18",
  "Heimat Display 20",
  "Heimat Mono",
  "Heimat Sans",
  "Heimat Stencil",
  "Hellenic Wide JF",
  "Hello My Love Ornaments",
  "Hello My Love Pro",
  "Henderson Sans Basic",
  "Henderson Slab Basic",
  "Henriette",
  "Henriette Compressed",
  "Herencia",
  "Hero New",
  "Hero New Hairline",
  "HerrVonMuellerhoff Pro",
  "Highgate",
  "Hightower Text",
  "Hilde Sharp",
  "Hind",
  "Hit",
  "Hobeaux",
  "Hobeaux Rococeaux",
  "Hobeaux Rococeaux Background",
  "Hobeaux Rococeaux Borders",
  "Hobeaux Rococeaux Sherman",
  "Hobo Std",
  "Hoffmann",
  "Hoffmann Titling",
  "Homemade Apple Pro",
  "Hooligan JF",
  "Hoosier Daddy",
  "Hot Salsa",
  "Hotel Open",
  "Hotel Solid",
  "Hothouse",
  "Hotsy Totsy MVB",
  "Hotsy Totsy MVB HiLite",
  "Hotsy Totsy MVB Rocksie",
  "Houschka Pro",
  "Houschka Rounded",
  "House of cards",
  "Hoverunit",
  "HT Neon",
  "Hucklebuck JF",
  "Hummingbird",
  "HWT Aetna",
  "HWT Aetna Condensed",
  "HWT Aetna Double Extra Condensed",
  "HWT Aetna Extra Condensed",
  "HWT Aetna Streamer Banner",
  "HWT Aetna Streamer Fill",
  "HWT Aetna Streamer Outline",
  "HWT Aetna Streamer Shadow",
  "HWT American Chromatic",
  "HWT American Inset",
  "HWT American Outline",
  "HWT American Shopworn",
  "HWT American Solid",
  "HWT American Stars",
  "HWT American Stars Bottom",
  "HWT American Stars Top",
  "HWT Antique Tuscan 9",
  "HWT Arabesque",
  "HWT Archimedes Hex",
  "HWT Archimedes Phillips",
  "HWT Archimedes Pro",
  "HWT Archimedes Screw",
  "HWT Archimedes Star",
  "HWT Artz",
  "HWT Bon Air",
  "HWT Borders One",
  "HWT Brylski",
  "HWT Bulletin",
  "HWT Catchwords",
  "HWT Etta East",
  "HWT Etta West",
  "HWT Geometric",
  "HWT Geometric Condensed",
  "HWT Geometric Shopworn",
  "HWT Geometric Shopworn Inked",
  "HWT Gothic Round",
  "HWT Lustig Elements",
  "HWT Lustig Elements Inline",
  "HWT Lustig Elements Inline Pro",
  "HWT Lustig Elements Inline SC",
  "HWT Lustig Elements Pro",
  "HWT Lustig Elements SC",
  "HWT Mardell",
  "HWT Republic Gothic Outline",
  "HWT Republic Gothic Solid",
  "HWT Roman Extended Fat Face",
  "HWT Roman Extended Light Face",
  "HWT Slab Antique",
  "HWT Slab Columbian",
  "HWT Star Ornaments",
  "HWT Tuscan Extended",
  "HWT Unit Gothic 716",
  "HWT Unit Gothic 717",
  "HWT Unit Gothic 718",
  "HWT Unit Gothic 719",
  "HWT Unit gothic 720",
  "HWT Unit Gothic 721",
  "HWT Unit Gothic 722",
  "HWT Van Lanen",
  "HWT Van Lanen Streamer",
  "Hydrophilia Iced",
  "Hydrophilia Liquid",
  "Hypatia Sans Pro",
  "Hypocrite",
  "IBM Plex Mono",
  "IBM Plex Sans",
  "IBM Plex Sans Condensed",
  "IBM Plex Serif",
  "ImaginaryFriend BB",
  "Immi Five O Five",
  "Imperial URW",
  "Impetus",
  "Impetus Inline",
  "Inconsolata",
  "Indie Flower",
  "Industry",
  "Industry Inc 3D",
  "Industry Inc Base",
  "Industry Inc Bevel",
  "Industry Inc Bevel Fill",
  "Industry Inc In-N-Out",
  "Industry Inc Inline",
  "Industry Inc Inline Stroke",
  "Industry Inc Outline",
  "Industry Inc Stencil",
  "Informa Pro",
  "Informa Pro Condensed",
  "Ingeborg Block",
  "Ingeborg Striped",
  "Ingra",
  "Ingra 2",
  "Ingra Condensed",
  "Ingra Condensed 2",
  "Ingra Wide",
  "Ingra Wide 2",
  "Input Mono",
  "Input Mono Compressed",
  "Input Mono Condensed",
  "Input Mono Narrow",
  "Input Sans",
  "Input Sans Compressed",
  "Input Sans Condensed",
  "Input Sans Narrow",
  "Input Serif",
  "Input Serif Compressed",
  "Input Serif Condensed",
  "Input Serif Narrow",
  "InterFace",
  "Interstate",
  "Interstate Compressed",
  "Interstate Condensed",
  "Interstate Mono",
  "Interstate Pi Four",
  "Interstate Pi One",
  "Interstate Pi Three",
  "Interstate Pi Two",
  "Iro Sans",
  "Iro Sans Display",
  "Ironstrike",
  "Ironstrike Stencil",
  "Ironwood Std",
  "Iskra",
  "Isonorm",
  "ITC American Typewriter",
  "ITC American Typewriter Condensed",
  "ITC Avant Garde Gothic Pro",
  "ITC Benguiat",
  "ITC Benguiat Condensed",
  "ITC Fenice Pro",
  "ITC Flora",
  "ITC Galliard",
  "ITC Giovanni",
  "ITC Officina Sans Pro",
  "ITC Slimbach",
  "ITC Zapf International",
  "IvyJournal",
  "IvyMode",
  "IvyPresto Display",
  "IvyPresto Headline",
  "IvyPresto Text",
  "IvyStyle Sans",
  "IvyStyle TW",
  "JAF Bernina Sans",
  "JAF Bernina Sans Compressed",
  "JAF Bernina Sans Condensed",
  "JAF Bernina Sans Narrow",
  "JAF Bernino Sans",
  "JAF Bernino Sans Compressed",
  "JAF Bernino Sans Condensed",
  "JAF Bernino Sans Narrow",
  "JAF Domus",
  "JAF Domus Titling",
  "JAF Facit",
  "JAF Herb",
  "JAF Herb Condensed",
  "JAF Johannes",
  "JAF Johannes Original",
  "JAF Lapture",
  "JAF Lapture Caption",
  "JAF Lapture Display",
  "JAF Lapture Subhead",
  "JAF Mashine",
  "JAF Mashine Rounded",
  "JAF Peacock",
  "JAF Peacock Alternate",
  "JAF Peacock Deep",
  "JAF Peacock Loop",
  "JAF Peacock Short",
  "JAF Peacock Swash",
  "JAF Zalamander",
  "JAF Zalamander Caps",
  "Jakob",
  "JanaThork-Pro",
  "Jay Gothic URW",
  "Jeanne Moderno",
  "Jeanne Moderno Geometrique",
  "Jeanne Moderno Titling",
  "Jeanne Moderno Ultra",
  "Jeff Script",
  "Jesaya",
  "Jinky",
  "Joanna Nova",
  "Joanna Sans Nova",
  "John Doe",
  "Joost",
  "Joschmi",
  "Josefin",
  "Josefin Sans",
  "Josefin Slab",
  "Journal",
  "Journal Ultra",
  "Jubilat",
  "Jubilat Thin",
  "Jumble",
  "Junegull",
  "Junegull Beach",
  "Juniper Std",
  "Justus Pro",
  "Kade",
  "Kaffeesatz",
  "Kallisto",
  "Kallisto Lined",
  "Kandal",
  "Kandin",
  "Kaneda Gothic",
  "Kanit",
  "KansasNew",
  "Kapelka New",
  "Kari Display Pro",
  "Kari Pro",
  "Kari Pro Wide",
  "Karmina",
  "Karmina Sans",
  "Katarine Web",
  "Kautiva Pro",
  "Kazimir",
  "KazimirText",
  "KazimirText 2",
  "Keedy Sans",
  "Kegger",
  "Kegger Collegiate",
  "Kelvingrove",
  "Kepler Std",
  "Kepler Std Caption",
  "Kepler Std Condensed Display",
  "Kepler Std Condensed Subhead",
  "Kepler Std Display",
  "Kepler Std Extended",
  "Kepler Std Extended Caption",
  "Kepler Std Extended Display",
  "Kepler Std Extended Subhead",
  "Kepler Std Semicondensed",
  "Kepler Std Semicondensed Caption",
  "Kepler Std Semicondensed Display",
  "Kepler Std Semicondensed Subhead",
  "Kepler Std Subhead",
  "Kestrel Script",
  "Kewl Script",
  "Kinescope",
  "Kinesis Pro 3",
  "King's Caslon",
  "King's Caslon Display",
  "Kiro",
  "Kobenhavn",
  "Kobenhavn C",
  "Kobenhavn C Stencil",
  "Kobenhavn CS",
  "Kobenhavn Pictos",
  "Kobenhavn Sans",
  "Kobenhavn Sans Stencil",
  "Kobenhavn Stencil",
  "Komet",
  "Komet SC",
  "Komu A",
  "Komu B",
  "Kon Tiki Aloha JF",
  "Kon Tiki Lounge JF",
  "Kopius",
  "Kopius Condensed",
  "Kopius Extras",
  "Korolev",
  "Korolev Compressed",
  "Korolev Condensed",
  "Korolev Military Stencil",
  "Korolev Rounded",
  "Kremlin Pro",
  "Kremlin Pro Expanded",
  "Kremlin Pro Semi Expanded",
  "Krete",
  "Krok",
  "Kudryashev D Contrast",
  "Kudryashev D Contrast Sans",
  "Kudryashev D ExContrast",
  "Kudryashev D ExContrast Sans",
  "Kulturista Web",
  "Kumlien Pro",
  "Kumlien Pro Condensed",
  "Kyrial Display Pro",
  "Kyrial Sans Condensed Pro",
  "Kyrial Sans Pro",
  "Laca",
  "Laca Text",
  "Lady Dodo",
  "Lakeside",
  "Lamar Pen",
  "Landa",
  "Larabiefont",
  "Laski Sans",
  "Laski Sans Stencil",
  "Laski Sans Thin",
  "Laski Slab",
  "Laski Slab Stencil",
  "Laski Slab Thin",
  "Latex",
  "Latex Bottom",
  "Latex Relief",
  "Latex Shadow",
  "Latex Top",
  "Latienne Pro",
  "Latinaires",
  "Latinaires Caps",
  "Latino URW",
  "Lato",
  "Lavigne Display",
  "Lavigne Text",
  "Le Havre",
  "Le Havre Rounded",
  "Le Monde Courrier Std",
  "Le Monde Courrier Std 2",
  "Le Monde Journal Std",
  "Le Monde Journal Std 2",
  "Le Monde Livre Classic Std",
  "Le Monde Livre Std",
  "Le Monde Sans Std",
  "Le Monde Sans Std 2",
  "League Gothic",
  "League Gothic Condensed",
  "Leander Script Pro",
  "Learning Curve",
  "Learning Curve Dashed",
  "Leather",
  "Legitima",
  "Lehmann",
  "Leksa",
  "Leksa Sans",
  "Lemance",
  "Lemongrass Caps",
  "Lemongrass Script",
  "Leo",
  "Leo Small Text",
  "Letter Gothic Std",
  "Lexia",
  "Lexia Advertising",
  "Lexia Mono",
  "LFT Etica",
  "LFT Etica Compressed",
  "LFT Etica Condensed",
  "LFT Etica Display",
  "LFT Etica Mono",
  "Liam",
  "Liana",
  "Liberation Sans",
  "Liberation Serif",
  "Libertad",
  "Liberteen",
  "Libre Franklin",
  "LiebeDoni Outline",
  "LiebeDoni Solid",
  "LiebeErika",
  "LiebeGerda",
  "LiebeRuth",
  "Ligurino",
  "Ligurino Condensed",
  "Linden Hill",
  "Linotype Didot",
  "Linotype Didot Headline",
  "Linotype Sabon",
  "Linotype Vectora",
  "Lint McCree",
  "Lipa Agate High",
  "Lipa Agate High Condensed",
  "Lipa Agate High Narrow",
  "Lipa Agate Low",
  "Lipa Agate Low Condensed",
  "Lipa Agate Low Narrow",
  "Liquorstore",
  "Liquorstore 3D",
  "Liquorstore Jazz",
  "Lisbeth",
  "Lisbeth Display",
  "Litania",
  "Lithos Pro",
  "Livermore Script ATF",
  "Livermore Script ATF Rough No.1",
  "Livermore Script ATF Rough No.2",
  "Livory",
  "Liza Caps",
  "Liza Display",
  "Liza Text",
  "Lo-Res 12",
  "Lo-Res 12 Bold Alt Oakland",
  "Lo-Res 12 Narrow",
  "Lo-Res 15",
  "Lo-Res 15 Bold Alt Oakland",
  "Lo-Res 15 Narrow",
  "Lo-Res 21 Serif",
  "Lo-Res 22 Bold Oakland",
  "Lo-Res 22 Narrow",
  "Lo-Res 22 Serif",
  "Lo-Res 28",
  "Lo-Res 28 Narrow",
  "Lo-Res 9 Minus Narrow",
  "Lo-Res 9 Minus Wide",
  "Lo-Res 9 Narrow",
  "Lo-Res 9 Plus Narrow",
  "Lo-Res 9 Plus Wide",
  "Lo-Res 9 Plus Wide Bold Alt",
  "Lo-Res 9 Wide",
  "Lo-Res 9 Wide Bold Alt Oakland",
  "Look Script",
  "Looking Flowers Caps",
  "Looking Flowers Deco",
  "Looking Flowers Script",
  "Lora",
  "Lorimer No 2",
  "Lorimer No 2 Condensed",
  "Lorimer No 2 Stencil",
  "Los Feliz",
  "LosLana Niu Pro",
  "Lourdes",
  "Louvette Banner",
  "Louvette Deck",
  "Louvette Display",
  "Louvette Text",
  "LTC Bodoni 175",
  "LTC Broadway",
  "LTC Broadway Engraved",
  "LTC Caslon Pro",
  "LTC Globe Gothic",
  "LTC Goudy Oldstyle Pro",
  "LTC Goudy Ornate",
  "LTC Hess Monoblack",
  "LTC Italian Old Style Pro",
  "LTC Kennerley",
  "LTC Pabst Oldstyle",
  "LTC Pabst Oldstyle Swash",
  "LTC Squareface",
  "LTC Squareface Small Caps",
  "Luke",
  "Luke Medium 200",
  "Luke Medium 300",
  "Luke Medium 400",
  "Luke Thick",
  "Luke Thick 200",
  "Luke Thick 300",
  "Luke Thick 400",
  "Luke Thin",
  "Luke Thin 200",
  "Luke Thin 300",
  "Luke Thin 400",
  "Luminance",
  "Luminance SC",
  "Lunatix",
  "Lush Script",
  "Lust",
  "Lust Didone",
  "Lust Didone Fine",
  "Lust Display",
  "Lust Display Didone",
  "Lust Fine",
  "Lust Sans",
  "Lust Script",
  "Lust Script Display",
  "Lust Script Fine",
  "Lust Stencil",
  "Lust Stencil Display",
  "Lust Stencil Fine",
  "Lust Text",
  "Luxus Brut",
  "Luxus Brut Sparkling",
  "Lynda",
  "M+ 1c",
  "M+ 1m"
];
const fontList = [...new Set(fontsWithPossibleDuplication)];
export default fontList;
