/* src/Fingerprinter.svelte generated by Svelte v3.38.2 */
import {
	SvelteComponent,
	append,
	attr,
	destroy_each,
	detach,
	element,
	init,
	insert,
	noop,
	safe_not_equal,
	set_data,
	set_style,
	space,
	text
} from "../_snowpack/pkg/svelte/internal.js";

import { onMount } from "../_snowpack/pkg/svelte.js";
import fontList from "./detector/fontList.js";

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[8] = list[i];
	return child_ctx;
}

// (97:6) {#each matches as match}
function create_each_block(ctx) {
	let li;
	let t0_value = /*match*/ ctx[8] + "";
	let t0;
	let t1;

	return {
		c() {
			li = element("li");
			t0 = text(t0_value);
			t1 = space();
			attr(li, "class", "list-disc");
			set_style(li, "font-family", "'" + /*match*/ ctx[8] + "', sans-serif");
		},
		m(target, anchor) {
			insert(target, li, anchor);
			append(li, t0);
			append(li, t1);
		},
		p(ctx, dirty) {
			if (dirty & /*matches*/ 64 && t0_value !== (t0_value = /*match*/ ctx[8] + "")) set_data(t0, t0_value);

			if (dirty & /*matches*/ 64) {
				set_style(li, "font-family", "'" + /*match*/ ctx[8] + "', sans-serif");
			}
		},
		d(detaching) {
			if (detaching) detach(li);
		}
	};
}

function create_fragment(ctx) {
	let div2;
	let p0;
	let t0;
	let t1;
	let t2;
	let t3;
	let br0;
	let t4;
	let div0;
	let p1;
	let t5;
	let t6;
	let t7;
	let p2;
	let t8;
	let t9;
	let t10;
	let span;
	let t11;
	let t12;
	let p3;
	let t13;
	let t14;
	let progress_1;
	let progress_1_max_value;
	let t15;
	let p4;
	let t16;
	let t17;
	let t18_value = fontList.length + "";
	let t18;
	let t19;
	let p5;
	let t20_value = (interval * (fontList.length - /*progress*/ ctx[2]) / 1000).toFixed(0) + "";
	let t20;
	let t21;
	let t22;
	let br1;
	let t23;
	let details;
	let summary;
	let t24_value = /*matches*/ ctx[6].length + "";
	let t24;
	let t25;
	let t26;
	let div1;
	let t31;
	let ul;
	let each_value = /*matches*/ ctx[6];
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block(get_each_context(ctx, each_value, i));
	}

	return {
		c() {
			div2 = element("div");
			p0 = element("p");
			t0 = text("Sans-serif width: ");
			t1 = text(/*sansWidth*/ ctx[4]);
			t2 = text("px");
			t3 = space();
			br0 = element("br");
			t4 = space();
			div0 = element("div");
			p1 = element("p");
			t5 = text("Comparing: ");
			t6 = text(/*font*/ ctx[5]);
			t7 = space();
			p2 = element("p");
			t8 = text(/*width*/ ctx[0]);
			t9 = text("px");
			t10 = space();
			span = element("span");
			t11 = text("mmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmwwwwwwwwwwwwwwwwwwwllllllllllliiiiiiiiii??????!!!!!");
			t12 = space();
			p3 = element("p");
			t13 = text(/*status*/ ctx[1]);
			t14 = space();
			progress_1 = element("progress");
			t15 = space();
			p4 = element("p");
			t16 = text(/*progress*/ ctx[2]);
			t17 = text(" of ");
			t18 = text(t18_value);
			t19 = space();
			p5 = element("p");
			t20 = text(t20_value);
			t21 = text(" seconds...");
			t22 = space();
			br1 = element("br");
			t23 = space();
			details = element("details");
			summary = element("summary");
			t24 = text(t24_value);
			t25 = text(" matches, click to show/hide");
			t26 = space();
			div1 = element("div");

			div1.innerHTML = `<p>psst! You may see fonts here that you aren&#39;t aware of. Your browser also
        usually comes with a few fonts, or it might substitute known fonts (eg
        Times New Roman) with a different installed font.</p> 
      <br/> 
      <p>However, this is still enough to fingerprint you. More sophisticated
        fingerprinters might include the pixel shift in your fingerprint, to
        even guess what font your browser is replacing that font with!</p>`;

			t31 = space();
			ul = element("ul");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			attr(p0, "class", "font-sans");
			attr(div0, "class", "flex justify-between items-center");
			attr(span, "id", "fingerprinter");
			attr(span, "class", "text-9xl border-0 absolute opacity-5 left-0 top-0");
			attr(span, "aria-hidden", "true");
			attr(span, "style", /*style*/ ctx[3]);
			attr(progress_1, "class", "w-full m-0");
			attr(progress_1, "max", progress_1_max_value = fontList.length);
			progress_1.value = /*progress*/ ctx[2];
			attr(summary, "class", "cursor-pointer font-bold");
			attr(div1, "class", "m-4 bg-yellow-300 p-2 rounded-md shadow-lg");
			attr(ul, "class", "ml-4");
			attr(details, "class", "bg-gray-100 rounded-lg p-2 max-h-96 overflow-y-auto");
		},
		m(target, anchor) {
			insert(target, div2, anchor);
			append(div2, p0);
			append(p0, t0);
			append(p0, t1);
			append(p0, t2);
			append(div2, t3);
			append(div2, br0);
			append(div2, t4);
			append(div2, div0);
			append(div0, p1);
			append(p1, t5);
			append(p1, t6);
			append(div0, t7);
			append(div0, p2);
			append(p2, t8);
			append(p2, t9);
			append(div2, t10);
			append(div2, span);
			append(span, t11);
			append(div2, t12);
			append(div2, p3);
			append(p3, t13);
			append(div2, t14);
			append(div2, progress_1);
			append(div2, t15);
			append(div2, p4);
			append(p4, t16);
			append(p4, t17);
			append(p4, t18);
			append(div2, t19);
			append(div2, p5);
			append(p5, t20);
			append(p5, t21);
			append(div2, t22);
			append(div2, br1);
			append(div2, t23);
			append(div2, details);
			append(details, summary);
			append(summary, t24);
			append(summary, t25);
			append(details, t26);
			append(details, div1);
			append(details, t31);
			append(details, ul);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(ul, null);
			}
		},
		p(ctx, [dirty]) {
			if (dirty & /*sansWidth*/ 16) set_data(t1, /*sansWidth*/ ctx[4]);
			if (dirty & /*font*/ 32) set_data(t6, /*font*/ ctx[5]);
			if (dirty & /*width*/ 1) set_data(t8, /*width*/ ctx[0]);

			if (dirty & /*style*/ 8) {
				attr(span, "style", /*style*/ ctx[3]);
			}

			if (dirty & /*status*/ 2) set_data(t13, /*status*/ ctx[1]);

			if (dirty & /*progress*/ 4) {
				progress_1.value = /*progress*/ ctx[2];
			}

			if (dirty & /*progress*/ 4) set_data(t16, /*progress*/ ctx[2]);
			if (dirty & /*progress*/ 4 && t20_value !== (t20_value = (interval * (fontList.length - /*progress*/ ctx[2]) / 1000).toFixed(0) + "")) set_data(t20, t20_value);
			if (dirty & /*matches*/ 64 && t24_value !== (t24_value = /*matches*/ ctx[6].length + "")) set_data(t24, t24_value);

			if (dirty & /*matches*/ 64) {
				each_value = /*matches*/ ctx[6];
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
					} else {
						each_blocks[i] = create_each_block(child_ctx);
						each_blocks[i].c();
						each_blocks[i].m(ul, null);
					}
				}

				for (; i < each_blocks.length; i += 1) {
					each_blocks[i].d(1);
				}

				each_blocks.length = each_value.length;
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(div2);
			destroy_each(each_blocks, detaching);
		}
	};
}

let interval = 10;

function sleep(ms) {
	return new Promise(resolve => {
			setTimeout(resolve, ms);
		});
}

function instance($$self, $$props, $$invalidate) {
	var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
		function adopt(value) {
			return value instanceof P
			? value
			: new P(function (resolve) {
						resolve(value);
					});
		}

		return new (P || (P = Promise))(function (resolve, reject) {
				function fulfilled(value) {
					try {
						step(generator.next(value));
					} catch(e) {
						reject(e);
					}
				}

				function rejected(value) {
					try {
						step(generator["throw"](value));
					} catch(e) {
						reject(e);
					}
				}

				function step(result) {
					result.done
					? resolve(result.value)
					: adopt(result.value).then(fulfilled, rejected);
				}

				step((generator = generator.apply(thisArg, _arguments || [])).next());
			});
	};

	let width = 0;
	let status = "";
	let progress = 0;
	let style;
	let sansWidth;
	let font = "defaults";
	let matches = [];

	onMount(() => __awaiter(void 0, void 0, void 0, function* () {
		$$invalidate(1, status = "Getting element...");
		yield sleep(1000);
		const fingerprinter = document.getElementById("fingerprinter");
		$$invalidate(1, status = "Computing default font widths...");
		$$invalidate(3, style = "font-family: sans-serif;");
		yield sleep(interval);
		$$invalidate(4, sansWidth = fingerprinter.scrollWidth);
		$$invalidate(1, status = "Fingerprinting...");
		let i = 0;
		let max = fontList.length - 1;

		let tick = setInterval(
			() => __awaiter(void 0, void 0, void 0, function* () {
				$$invalidate(3, style = `font-family: '${fontList[i]}', sans-serif`);
				yield sleep(interval);
				$$invalidate(0, width = fingerprinter.scrollWidth);

				if (width !== sansWidth) {
					$$invalidate(6, matches = [...matches, fontList[i]]);
				}

				$$invalidate(5, font = fontList[i]);
				$$invalidate(2, progress = i);
				i++;

				if (i >= max) {
					clearInterval(tick);
					$$invalidate(1, status = "Done!");
				}
			}),
			interval
		);
	}));

	return [width, status, progress, style, sansWidth, font, matches];
}

class Fingerprinter extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default Fingerprinter;