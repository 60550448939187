/* src/Explanation.svelte generated by Svelte v3.38.2 */
import {
	SvelteComponent,
	attr,
	detach,
	element,
	init,
	insert,
	noop,
	safe_not_equal,
	set_style,
	space
} from "../_snowpack/pkg/svelte/internal.js";

function create_fragment(ctx) {
	let h2;
	let t1;
	let hr;
	let t2;
	let p0;
	let t4;
	let pre;
	let t6;
	let p1;
	let t8;
	let br0;
	let t9;
	let p2;
	let t11;
	let br1;
	let t12;
	let p3;
	let t14;
	let br2;
	let t15;
	let p4;
	let t17;
	let br3;
	let t18;
	let p5;
	let t20;
	let ul;

	return {
		c() {
			h2 = element("h2");
			h2.textContent = "How?";
			t1 = space();
			hr = element("hr");
			t2 = space();
			p0 = element("p");
			p0.textContent = "CSS allows you to set font fallbacks, like so:";
			t4 = space();
			pre = element("pre");

			pre.innerHTML = `<code>p {
  font-family: &#39;Chiller&#39;, monospace;
}</code>`;

			t6 = space();
			p1 = element("p");
			p1.textContent = "If you have Chiller installed, this text will render using that. Otherwise,\n  this text will appear monospaced. Try opening this page on a Windows computer\n  (likely has Chiller installed) or your phone (likely doesn't)!";
			t8 = space();
			br0 = element("br");
			t9 = space();
			p2 = element("p");
			p2.textContent = "This is all you need to create a fingerprint of a browser just from the fonts\n  you have installed.";
			t11 = space();
			br1 = element("br");
			t12 = space();
			p3 = element("p");
			p3.textContent = "You've probably noticed that the title text is changing in width -- that's how\n  the script detects what fonts you have on your system.";
			t14 = space();
			br2 = element("br");
			t15 = space();
			p4 = element("p");
			p4.textContent = "Render a piece of text in a certain typeface, and then test against each\n  fallback font. If the width changes (detected via JavaScript), the font is\n  probably installed. Cycle through thousands of fonts and you can come up with\n  a pretty good profile of a browser, if you have installed any fonts that are\n  unusual for your operating system.";
			t17 = space();
			br3 = element("br");
			t18 = space();
			p5 = element("p");
			p5.textContent = "This can be prevented by:";
			t20 = space();
			ul = element("ul");

			ul.innerHTML = `<li>Not installing any fonts outside of your OS&#39;s defaults</li> 
  <li>Using a browser extension to add noise to your fingerprint</li> 
  <li>Disabling JavaScript</li>`;

			attr(h2, "class", "text-2xl font-semibold pb-2");
			attr(pre, "class", "code");
			set_style(p1, "font-family", "Chiller, monospace");
			attr(ul, "class", "list-disc ml-8");
		},
		m(target, anchor) {
			insert(target, h2, anchor);
			insert(target, t1, anchor);
			insert(target, hr, anchor);
			insert(target, t2, anchor);
			insert(target, p0, anchor);
			insert(target, t4, anchor);
			insert(target, pre, anchor);
			insert(target, t6, anchor);
			insert(target, p1, anchor);
			insert(target, t8, anchor);
			insert(target, br0, anchor);
			insert(target, t9, anchor);
			insert(target, p2, anchor);
			insert(target, t11, anchor);
			insert(target, br1, anchor);
			insert(target, t12, anchor);
			insert(target, p3, anchor);
			insert(target, t14, anchor);
			insert(target, br2, anchor);
			insert(target, t15, anchor);
			insert(target, p4, anchor);
			insert(target, t17, anchor);
			insert(target, br3, anchor);
			insert(target, t18, anchor);
			insert(target, p5, anchor);
			insert(target, t20, anchor);
			insert(target, ul, anchor);
		},
		p: noop,
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(h2);
			if (detaching) detach(t1);
			if (detaching) detach(hr);
			if (detaching) detach(t2);
			if (detaching) detach(p0);
			if (detaching) detach(t4);
			if (detaching) detach(pre);
			if (detaching) detach(t6);
			if (detaching) detach(p1);
			if (detaching) detach(t8);
			if (detaching) detach(br0);
			if (detaching) detach(t9);
			if (detaching) detach(p2);
			if (detaching) detach(t11);
			if (detaching) detach(br1);
			if (detaching) detach(t12);
			if (detaching) detach(p3);
			if (detaching) detach(t14);
			if (detaching) detach(br2);
			if (detaching) detach(t15);
			if (detaching) detach(p4);
			if (detaching) detach(t17);
			if (detaching) detach(br3);
			if (detaching) detach(t18);
			if (detaching) detach(p5);
			if (detaching) detach(t20);
			if (detaching) detach(ul);
		}
	};
}

class Explanation extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, null, create_fragment, safe_not_equal, {});
	}
}

export default Explanation;